import React, { useState, useEffect, useRef } from "react";
import { DesktopBg, Logo, PlayIcon } from "../../assets/svgs/index";
import PhoneMock from "../../assets/images/phone-mock.png";
import "./index.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Drawer } from "antd";
import { Button } from "../Button/index";
import MobileImage from "../../assets/images/mobiledskimg.png";
import DesktopImage from "../../assets/images/dskimg.png";
import GHeroImg from "../../assets/images/headingbg.png";
import ParticleBackground from "react-particle-backgrounds";
import { colors } from "../../constants/color";
import Typed from "typed.js";
import Lines from "../../assets/images/Vector.png";
import NewBgAttachment from "../../assets/images/newbg-attachment.png";
import LeftBody from "../../assets/images/Group 4077 (1).png";
import CardBody from "../../assets/images/Group 4078 (1).png";
import IPhomeImg from "../../assets/images/[Mockup] iPhone 14.png";
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import {motion, useScroll, useTransform} from "framer-motion";


import { useTranslation } from "react-i18next";
export default function Header({
  title,
  sub,
  headerStyle,
  subitle,
  buttonText,
  button,
  noStyle,
  imgGrad,
}) {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const active = window.location.pathname.replace("/", "");

  const toggle = () => setIsOpen(!isOpen);
  const BtnStyle = {
    borderRadius: "8px",
    color: "#fff",
    fontWeight: 500,
    fontSize: "14px",
    padding: "0px 40px",
    lineHeight: "21px",
    border: "transparent",
    outline: "1px solid #001755 !important",
    outlineColor: "#001755 !important",
    background: "#001755",
    height: "41px",
    border: "1px solid #001755",
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  



  const pathname = window.location.pathname;

  const element = useRef(null);
  const typed = useRef(null);
  useEffect(() => {

    const options = {

      strings: [
        t("landing.heroCaptionTwo"),
        t("landing.heroCaptionThree"),
        t("landing.heroCaptionFour"),
        t("landing.heroCaptionFive"),
        t("landing.heroCaptionSix"),
        // `Create budgets, and <br /> track your expenses.`,
        // "Downstream Retail Accountability System",
      ],
      typeSpeed: 90,
      backSpeed: 60,
      loop: true,
      showCursor: false,
    };
    typed.current = new Typed(element.current, options);
    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    };
  }, []);
  

  return (
    
    <div className="header-hero overflow-hidden mx-auto  " >
      
      
        
    <div className="parallax" >
      <div
        className="container-fluid header-container position-relative "
        style={{}}
      >
       
        {/* lines */}
        
        {/* {<div
          className="position-absolute   d-none d-sm-none d-md-none d-lg-block"
          style={{
            left: 10,
            bottom: 0,
          }}
        >
          <img
            src={Lines}
            alt={"lines"}
            className="img-fluid w-100 h-100"
            loading="lazy"
          />
        </div> } */}
        {/* mockup */}
        
        {/* <div
          className="position-absolute left-body animate__animated animate__fadeInRight d-none d-sm-none d-md-none d-lg-block"
          style={{
            right: 0,
            bottom: -100,
            width: 800,
          }}
        >
          <img src={LeftBody} alt={"lines"} className="img-fluid w-100 h-100 leftbody-img" />
        </div> */}
        {/* card */}
        {/* <div
          className="position-absolute card-body card__ animate__animated animate__fadeInUp d-none d-sm-none d-md-none d-lg-block"
          style={{
            left: "33%",
            bottom:"-7%",
            width: 300,
            zIndex:10,
          
          }}
        >
          <img
            src={CardBody}
            alt={"lines"}
            className="img-fluid w-100 h-100 cardbody-img"
            loading="lazy"
          />
        </div> */}
       
        <div
          className="row header-row mt-lg-3 d-flex align-items-start justify-content-between center justify-content-sm-between justify-content-md- 
           center justify-content-lg-between px-sm-4 "
          style={{}}
        >
          {/* left view */}
          <div
            className="col-sm-10 col-md-8 col-lg-5 col-xl-5 animate__animated animate__fadeInLeft"
            // data-aos="fade-right"
            // data-aos-duration="2000"
          >
            <div className="typed-cover" style={{}}>
              <div
                className=" typed-text lead-text text-left text-sm-left text-md-center text-lg-left"
                style={{
                  height: 250,
                }}
              >
                {" "}
                {t("landing.heroCaptionOne")}
                <div className="" style={{}} ref={element} />
              </div>

              <br />
              <div className=" lead-light-text text-left text-sm-center text-md-center text-lg-left mt-0 mt-sm-0 mt-md-0 mt-lg-2"
              
              dangerouslySetInnerHTML={{ __html: t("landing.caption") }} 
              >
                {/* {t("landing.caption")} */}
              </div>
              <div className="pt-5 mt-3 justify-content align-items-bottom d-none d-lg-flex d-sm-none">
                <div>
                  <Button
                    text={t("landing.cta.btnOne")}
                    style={{ height: "53px" }}
                    className="dark"
                  />
                </div>
                <div className="pt-2 d-flex justify-content align-items-center">
                  <p className="pl-5 dark-h-text">{t("landing.cta.btnTwo")}</p>
                  <p className="pl-3 d-none">
                    <PlayIcon />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-10 col-md-8 d-block d-sm-block d-md-block d-lg-none position-relative overflow-hidden">
            <div
              className="w-100"
              style={{
                height: "auto",
                marginTop: 100,
              }}
            >
              <div className="">
                <img
                  src={IPhomeImg}
                  className="w-100 h-100 img-fluid"
                  alt="iphone img"
                  loading="lazy"
                />
              </div>
              <div className="">
                <div className="w-100 pt-5 d-block d-flex align-items-center justify-content-center">
                  <Button
                    text="Schedule a demo"
                    style={{ height: "53px", width: 300 }}
                    className="dark "
                  />
                </div>
                <div className="w-100 mt-3 d-block d-flex align-items-center justify-content-center">
                  <Button
                    text="Contact sales"
                    style={{
                      height: "53px",
                      width: 300,
                      fontSize: 17,
                      color: colors.textColor,
                    }}
                    className="dark bg-transparent font-weight-bold  "
                  />
                </div>
              </div>
            </div>
          </div>
          {/* desktop image */}
        </div>
      </div>
      </div>
      
    </div>
  );
}

{
  /* <div
className="animate__animated animate__fadeInRight  col-sm-10 col-md-6 col-lg-6 col-xl-6 d-none d-sm-none d-md-none d-lg-none"
style={{
  paddingRight: 0,
  marginLeft: 0,
}}
>
<div className="hero-right_cover">
  <div className="hero-desktop_image shadow-lg">
    <DesktopBg />
  </div>
  <div
    className="phone-hero"
    style={{
      zIndex: 120,
    }}
  >
    <img src={PhoneMock} style={{ width: "110%" }} />
  </div>
</div>
</div> */
}
