import "./App.css";
import AppRouter from "./routes/routes";
import AOS from "aos";
import React, { useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      <ParallaxProvider>
        <AppRouter />
      </ParallaxProvider>
    </div>
  );
}

export default App;
