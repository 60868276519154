import React from 'react'
import Lines from "../../assets/images/Vector.png";
import storyimg from "../../assets/images/storyimg.png";

const ourStory = () => {
  return (
    <div>
 <div className="">
          <div>
            <div className="bg-white ourstory-section"
            //  data-aos="fade-right"
            //  data-aos-duration="2000"
             
             >
              <div className="row align-items-center position-relative ">
                <div
                  className="position-absolute  d-none d-sm-none d-md-none d-lg-block"
                  style={{
                    left: 10,
                    bottom: 0,
                  }}
                >
                  <img
                    src={Lines}
                    alt={"lines"}
                    className="img-fluid w-100 h-100"
                    loading="lazy"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6  my-3 my-sm-0  my-md-0  my-lg-0 ">
                  <div className="d-flex justify-content-center ">
                    <div className=" pt-4">
                      <h2
                        className=""
                        style={{
                          fontFamily: "Quiet Sans",
                          fontSize: " 48px",
                          fontWeight: 600,
                          lineHeight: " 62px",
                          letterSpacing: "-2px",
                          textAlign: "justify",
                        }}
                      >
                        Our Story
                      </h2>
                      <p className="ourstory-text">
                        Lorem ipsum dolor sit amet, consectetur <br />{" "}
                        adipiscing elit. Pellentesque scelerisque, lacus <br />{" "}
                        id tempus consectetur, lorem felis finibus <br />{" "}
                        turpis, id posuere arcu urna et nulla. Sed ut <br />{" "}
                        pharetra turpis, tempus cursus lacus. Proin in <br />{" "}
                        est augue. Nunc pulvinar lorem mauris, non
                        <br />
                        feugiat sapien egestas at. In commodo <br /> condimentum
                        lacus,
                      </p>

                      <p className="ourstory-text">
                        Lorem ipsum dolor sit amet, consectetur <br />{" "}
                        adipiscing elit. Pellentesque scelerisque, lacus <br />{" "}
                        id tempus consectetur, lorem felis finibus <br />{" "}
                        turpis, id posuere arcu urna et nulla. Sed ut <br />{" "}
                        pharetra turpis, tempus cursus lacus. Proin in <br />{" "}
                        est augue. Nunc pulvinar lorem mauris, non
                        <br />
                        feugiat sapien egestas at. In commodo <br /> condimentum
                        lacus,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 hide-mobile  ">
                  <div className="d-flex justify-content-center align-items-start">
                    <img
                      className=""
                      src={storyimg}
                      style={{
                        height: "620px",
                        width: "525px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ourStory