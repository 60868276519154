import Header from "../../components/Header/index";
import {
  CardIcon,
  FundTransfer,
  Cards,
  Station,
  Comp,
  AutoMobile,
  AppleDownload,
  LogoWhite,
  PlayDownload,
  Nuzzle,
  BrowserFrame,
} from "../../assets/svgs";
import PhoneMock from "../../assets/images/phone-mock.png";

import "./index.css";
import AltCard from "../../assets/images/alt-card.png";
import Frame1 from "../../assets/images/frame1.png";
import Frame4 from "../../assets/images/frame4.png";
import twitter from "../../assets/images/twitterrm.png";
import fb from "../../assets/images/facebuk.png";
import insta from "../../assets/images/instagra.png";
import MockupAuto from "../../assets/images/mockup-auto.png";
import TakeCharge from "./TakeChargeSection";
import ExpenseMgt from "./Expense";
import ExpenseCards from "./ExpenseCards";
import FleetManagement from "./FleetManagement";
import BusinessSolution from "./BusinessSolution";
import Decisions from "./Decisions";
import ExpenseTracking from "./ExpenseTracking";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar";
import React, { useEffect , useRef} from "react";
import { Helmet } from "react-helmet";
import BackToTopBtn from "../../components/ToTop/BackToTopBtn";


function Landing() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    return () => {};
  }, []);
  

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Remis :: Home</title>
      </Helmet>
      <NavBar />
      <Header />
      <TakeCharge />
      <ExpenseMgt />
      <ExpenseCards />
      <FleetManagement />
      <BusinessSolution />
      <BackToTopBtn/>
      <ExpenseTracking />
      <Decisions />
      <Footer />
    </div>
  );
}

export default Landing;
