import { FundTransfer, Comp, Nuzzle } from "../../assets/svgs";
import { Parallax } from "react-scroll-parallax";
const BusinessCard = ({ data }) => {
  return (
    <>
      <div
        className="col-md-4 my-3 d-none d-sm-none d-md-none d-lg-none"
        style={{}}
      >
        <Parallax translateX={[-120, 150]} speed={10}>
          <div className="border-content">
            <div className="business-card pb-4 d-flex align-items-center justify-content-center">
              {data?.tag == "funds" ? (
                <FundTransfer />
              ) : data?.tag == "nuzzle" ? (
                <Nuzzle />
              ) : (
                <Comp />
              )}
              <br />
            </div>
            <div className="heading-p-sm pb-3 text-white">{data?.title}</div>
            <div className="text-dark-mute">{data?.desc}</div>
          </div>
        </Parallax>
      </div>
      <div
        className="col-md-6 col-lg-4 col-sm-12 my-3 d-sm-block d-md-block d-lg-block"
        style={{}}
      >
        <div className="border-content">
          <div className="business-card pb-4 d-flex align-items-center justify-content-center">
            {data?.tag == "funds" ? (
              <FundTransfer />
            ) : data?.tag == "nuzzle" ? (
              <Nuzzle />
            ) : (
              <Comp />
            )}
            <br />
          </div>
          <div className="heading-p-sm pb-3 text-white">{data?.title}</div>
          <div className="text-dark-mute">{data?.desc}</div>
        </div>
      </div>
    </>
  );
};

export default BusinessCard;
