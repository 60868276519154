import { Logo, NewLogo, WhiteLogo } from "../../assets/svgs";
import { AiOutlineMenu } from "react-icons/ai";
import { colors } from "../../constants/color";
import { Link, useHistory, useLocation } from "react-router-dom";
import Modal from "../Modal";
import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import FeaturesDd from "./Dropdown/FeaturesDd";
import SupportDd from "./Dropdown/SupportDd";
import WHlogo from "../../assets/images/whitelogo1.png";
import { Select } from "antd";
import { useTranslation, t } from "react-i18next";
import { LANGUAGES } from "../../constants/lang";

const NavBar = () => {
  const { Option } = Select;
  const { i18n, t } = useTranslation();
  const onChangeLang = (e) => {
    i18n.changeLanguage(e);
  };
  const BtnStyle = {
    borderRadius: "8px",
    color: "#fff",
    fontWeight: 500,
    fontSize: "14px",
    padding: "0px 40px",
    lineHeight: "21px",
    border: "transparent",
    // outline: "1px solid #001755 !important",
    // outlineColor: "#001755 !important",
    background: "#001755",
    height: "41px",
    border: "1px solid #001755",
  };
  const history = useHistory();
  const [showNav, setshowNav] = useState(false);
  const [modal, setmodal] = useState(false);

  const [access, setAccess] = useState(false);
  const menuRef = useRef();
  const featureRef = useRef();
  const supportRef = useRef();

  const [toggle, settoggle] = useState(true);
  const [open, setOpen] = useState(true);
  const [visible, setVisisble] = useState(false);

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!menuRef.current.contains(e.target)) {
  //       settoggle(true);
  //     }
  //   };
  //   // function handleScrollOutside() {
  //   //   settoggle(false);
  //   // }
  //   //
  //   const handleScrollOutside = (e) => {
  //     if (!menuRef.current.contains(e.target)) {
  //       settoggle(true);
  //     }
  //   };

  //   document.addEventListener("mousedown", handler);
  //   document.addEventListener("mouseout", handleScrollOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //     document.removeEventListener("mouseout", handleScrollOutside);
  //   };
  // });
  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!supportRef.current.contains(e.target)) {
  //       setOpen(true);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });

  const location = useLocation();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 30) {
        setshowNav(true);
      } else {
        setshowNav(false);
      }
    });
    return () => {};
  }, []);

  const colorLink = (path) => {
    switch (path) {
      case path == "/expense":
        return "#fff";
      default:
        return "";
    }
  };

  const WhiteNewLogo = () => {
    return (
      <div
        className=""
        style={{
          height: 35,
          width: 100,
        }}
      >
        <img src={WHlogo} className="w-100 h-100 img-fluid" alt="logo" />
      </div>
    );
  };
  const pathname = window.location.pathname;

  return (
    <div
      className={
        showNav
          ? "bg-white  w-100 d-flex align-items-center shadow-lg position-fixed px-4 nav-fs "
          : "mx-auto w-100 d-flex align-items-center position-fixed px-3 nav-fs"
      }
      style={{
        height: 80,
        zIndex: 200,
      }}
    >
      {modal && <Modal closeModal={setmodal} />}
      <div
        className={`mx-auto d-flex justify-content-between nav-section align-items-center w-100`}
      >
        <div className="w-100 d-flex align-items-center">
          <div className="pl-lg-3 pb-2">
            <div onClick={() => history.push("/")}>
              {location.pathname == "/" || location.pathname == "/faq" ? (
                <NewLogo />
              ) : location.pathname == "/expense" && showNav ? (
                <NewLogo />
              ) : location.pathname == "/expense" && !showNav ? (
                <WhiteNewLogo />
              ) : location.pathname == "/contact" && showNav ? (
                <NewLogo />
              ) : location.pathname == "/contact" && !showNav ? (
                <NewLogo />
              ) : location.pathname == "/trips" && showNav ? (
                <NewLogo />
              ) : location.pathname == "/trips" && !showNav ? (
                <WhiteNewLogo />
              ) : location.pathname == "/fleet" && showNav ? (
                <NewLogo />
              ) : location.pathname == "/fleet" && !showNav ? (
                <WhiteNewLogo />
              ) : location.pathname == "/cards" && showNav ? (
                <NewLogo />
              ) : location.pathname == "/cards" && !showNav ? (
                <WhiteNewLogo />
              ) : location.pathname == "/about" && showNav ? (
                <NewLogo />
              ) : location.pathname == "/about" && !showNav ? (
                <NewLogo />
              ) : null}
            </div>
          </div>
          <div className="d-none d-md-none d-lg-block d-lg-flex align-items-center ml-5">
            <div className="d-flex">
              <a
                // onClick={() => {
                //   setOpen(true);
                //   settoggle(true);
                // }}
                href="/#why"
                className="pr-5 text-decoration-none  pointer features-nav__btn"
                style={{
                  fontSize: 18,
                  color:
                    window.location.pathname == "/expense" && !showNav
                      ? "#fff"
                      : window.location.pathname == "/expense" && showNav
                      ? "black"
                      : window.location.pathname == "/#why" && !showNav
                      ? "#fff"
                      : window.location.pathname == "/#why" && showNav
                      ? "black"
                      : window.location.pathname == "/" && showNav
                      ? "black"
                      : window.location.pathname == "/" && !showNav
                      ? "black"
                      : window.location.pathname == "/cards" && !showNav
                      ? "#fff"
                      : window.location.pathname == "/cards" && showNav
                      ? "black"
                      : window.location.pathname == "/trips" && !showNav
                      ? "#fff"
                      : window.location.pathname == "/trips" && showNav
                      ? "black"
                      : window.location.pathname == "/fleet" && !showNav
                      ? "#fff"
                      : window.location.pathname == "/fleet" && showNav
                      ? "black"
                      : window.location.pathname == "/faq" && showNav
                      ? "black"
                      : window.location.pathname == "/faq" && !showNav
                      ? "black"
                      : window.location.pathname == "/contact" && showNav
                      ? "black"
                      : window.location.pathname == "/contact" && !showNav
                      ? "black"
                      : window.location.pathname == "/about" && showNav
                      ? "black"
                      : window.location.pathname == "/about" && !showNav
                      ? "black"
                      : "#fff",
                }}
              >
                {t("navBar.headerOne")}
              </a>
              <div
                className="pr-1 mr-3 d-flex align-items-center position-relative features-nav__btn "
                // ref={menuRef}
                style={{
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   if (!open) {
                //     setOpen(true);
                //   }
                //   settoggle((prev) => !prev);
                // }}
              >
                {/* {!toggle && } */}
                <FeaturesDd />
                <div
                  className="pointer "
                  style={{
                    color:
                      window.location.pathname == "/" && !showNav
                        ? "black"
                        : window.location.pathname == "/faq" && !showNav
                        ? "black"
                        : window.location.pathname == "/expense" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/trips" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/cards" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/fleet" && !showNav
                        ? "#fff"
                        : "",
                  }}
                >
                  {t("navBar.headerTwo")}
                </div>
                {/*  */}
                <div className="d-flex align-items-center justify-content-center pl-3 arrow">
                  <div className="d-none up">
                    <RiArrowUpSLine
                      color={
                        window.location.pathname == "/" && !showNav
                          ? "black"
                          : window.location.pathname == "/faq" && !showNav
                          ? "black"
                          : window.location.pathname == "/expense" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/trips" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/cards" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/fleet" && !showNav
                          ? "#fff"
                          : "black"
                      }
                    />
                  </div>
                  <div className="down">
                    <RiArrowDownSLine
                      color={
                        window.location.pathname == "/" && !showNav
                          ? "black"
                          : window.location.pathname == "/faq" && !showNav
                          ? "black"
                          : window.location.pathname == "/expense" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/trips" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/cards" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/fleet" && !showNav
                          ? "#fff"
                          : ""
                      }
                    />
                  </div>
                </div>
                {/*  */}
              </div>
              <div className="pr-5 ">
                <a
                  href={"https://remisafrica.medium.com/"}
                  className="d-block text-decoration-none text-black"
                  target={"_blank"}
                  style={{
                    color:
                      window.location.pathname == "/expense" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/expense" && showNav
                        ? "black"
                        : window.location.pathname == "/#why" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/#why" && showNav
                        ? "black"
                        : window.location.pathname == "/" && showNav
                        ? "black"
                        : window.location.pathname == "/" && !showNav
                        ? "black"
                        : window.location.pathname == "/cards" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/cards" && showNav
                        ? "black"
                        : window.location.pathname == "/trips" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/trips" && showNav
                        ? "black"
                        : window.location.pathname == "/fleet" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/fleet" && showNav
                        ? "black"
                        : window.location.pathname == "/faq" && showNav
                        ? "black"
                        : window.location.pathname == "/faq" && !showNav
                        ? "black"
                        : window.location.pathname == "/contact" && showNav
                        ? "black"
                        : window.location.pathname == "/contact" && !showNav
                        ? "black"
                        : window.location.pathname == "/about" && showNav
                        ? "black"
                        : window.location.pathname == "/about" && !showNav
                        ? "black"
                        : "#fff",
                  }}
                >
                  {" "}
                  Blog
                </a>
              </div>
              <div
                className="pr-1 d-flex align-items-center position-relative features-nav__btn  "
                // ref={supportRef}
                // onClick={() => {
                //   if (!toggle) {
                //     settoggle(true);
                //   }
                //   setOpen((prev) => !prev);
                // }}
                style={{
                  cursor: "pointer",
                }}
              >
                <SupportDd />
                {/* {!open && } */}

                <div
                  className="pointer"
                  style={{
                    color:
                      window.location.pathname == "/" && !showNav
                        ? "black"
                        : window.location.pathname == "/faq" && !showNav
                        ? "black"
                        : window.location.pathname == "/expense" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/trips" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/cards" && !showNav
                        ? "#fff"
                        : window.location.pathname == "/fleet" && !showNav
                        ? "#fff"
                        : "",
                  }}
                >
                  Support
                </div>

                <div className="d-flex align-items-center justify-content-center pl-3 arrow">
                  <div className="d-none up">
                    <RiArrowUpSLine
                      color={
                        window.location.pathname == "/" && !showNav
                          ? "black"
                          : window.location.pathname == "/faq" && !showNav
                          ? "black"
                          : window.location.pathname == "/expense" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/trips" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/cards" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/fleet" && !showNav
                          ? "#fff"
                          : ""
                      }
                    />
                  </div>
                  <div className="down">
                    <RiArrowDownSLine
                      color={
                        window.location.pathname == "/" && !showNav
                          ? "black"
                          : window.location.pathname == "/faq" && !showNav
                          ? "black"
                          : window.location.pathname == "/expense" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/trips" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/cards" && !showNav
                          ? "#fff"
                          : window.location.pathname == "/fleet" && !showNav
                          ? "#fff"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" d-flex align-items-center justify-content-between">
          <a
            href={"https://app.remis.africa/login"}
            className="d-none  mx-sm-3 text-decoration-none d-md-flex d-lg-flex align-items-center justify-content-center "
            style={{
              width: 40,
              height: 41,
              borderRadius: 50,
              // border:
              //   pathname == "/fleet" && !showNav
              //     ? "1px solid #fff"
              //     : pathname == "/expense" && !showNav
              //     ? "1px solid #fff"
              //     : pathname == "/cards" && !showNav
              //     ? "1px solid #fff"
              //     : pathname == "/trips" && !showNav
              //     ? "1px solid #fff"
              //     : " 1px solid #333",
              background:
                pathname == "/" && !showNav
                  ? "transparent"
                  : !showNav
                  ? "#transparent"
                  : "transparent",
            }}
            target={"_blank"}
          >
            {/* "#", */}
            <div
              className="d-block  "
              style={{
                color:
                  pathname == "/" && !showNav
                    ? "black"
                    : pathname == "/contact" && !showNav
                    ? "black"
                    : pathname == "/expense" && !showNav
                    ? "#fff"
                    : pathname == "/fleet" && !showNav
                    ? "#fff"
                    : pathname == "/cards" && !showNav
                    ? "#fff"
                    : pathname == "/trips" && !showNav
                    ? "#fff"
                    : "black",
              }}
            >
              Login
            </div>
          </a>
          <a
            href={"https://app.remis.africa/Signup"}
            className="d-none mx-sm-3 text-decoration-none d-md-none d-lg-block d-md-block d-lg-flex d-md-flex align-items-center justify-content-center"
            style={{
              width: 180,
              height: 41,
              borderRadius: 50,
              background:
                // pathname == "/" && !showNav
                //   ? "#001755"
                //   : !showNav
                //   ? "#fff"
                //   : "#001755",
                pathname == "/" && !showNav
                  ? "#001755"
                  : pathname == "/contact" && !showNav
                  ? "#001755"
                  : pathname == "/expense" && !showNav
                  ? "#fff"
                  : pathname == "/fleet" && !showNav
                  ? "#fff"
                  : pathname == "/cards" && !showNav
                  ? "#fff"
                  : pathname == "/trips" && !showNav
                  ? "#fff"
                  : "#001755",
            }}
            target={"_blank"}
          >
            {/* "#", */}
            <div
              className="d-inline"
              style={{
                color:
                  // pathname == "/" && !showNav
                  //   ? "#fff"
                  //   : !showNav
                  //   ? "#001755"
                  //   : "#fff",
                  pathname == "/" && !showNav
                    ? "#fff"
                    : pathname == "/contact" && !showNav
                    ? "#fff"
                    : pathname == "/expense" && !showNav
                    ? "black"
                    : pathname == "/fleet" && !showNav
                    ? "black"
                    : pathname == "/faq" && !showNav
                    ? "#fff"
                    : pathname == "/cards" && !showNav
                    ? "black"
                    : pathname == "/trips" && !showNav
                    ? "black"
                    : "#fff",
              }}
            >
              {t("navBar.headerThree")}
            </div>
          </a>
        </div>

        <div
          className="mr-4 d-none"
          style={{
            width: 50,
          }}
        >
          <Select
            // defaultValue={"EN"}
            onChange={onChangeLang}
            options={LANGUAGES}
          />
        </div>
        <div
          className="d-sm-block d-md-block d-lg-none pr-2"
          onClick={() => setmodal(true)}
        >
          <AiOutlineMenu
            size={30}
            color={
              pathname == "/" && !showNav
                ? "#000000"
                : !showNav
                ? "#fff"
                : colors.textColor
            }
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
