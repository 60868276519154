import { Link } from "react-router-dom";
// import {
//  Contact,
//   FaqImg
// } from "../../../assets/svgs/";
import Contact from "../../../assets/svgs/contact.svg";
import FaqImg from "../../../assets/svgs/faqImg.svg";
import "./style.css";
import { useTranslation } from "react-i18next";

const SupportDd = () => {
  const { i18n, t } = useTranslation();
  return (
    <div
      className="position-absolute features-dd_cover animate__animated animate__fadeIn px-4 support-cover"
      style={{
        width: 446,
      }}
    >
      <div
        className="position-relative bg-white shadow-sm"
        style={{
          top: -80,
          border: "1px solid #EAF0FF",
          borderRadius: 10,
        }}
      >
        <div className="container-fluid position-relative ">
          <div className="support px-3">
            <div className=" my-3 py-2">
              <Link
                to="/faq"
                className="d-flex align-items-center pointer text-decoration-none"
              >
                <div className="support-dd_icon expense d-flex align-items-center justify-content-center">
                  {/* <FaqImg /> */}
                  <img src={FaqImg} />
                </div>
                <div className="ml-3">
                  <div className="features-dd_title text-decoration-none pointer text-dark">
                    FAQ
                  </div>
                  <div className="feature-dd_caption text-decoration-none pointer">
                    check out frequently asked question
                  </div>
                </div>
              </Link>
            </div>
            <div className=" my-3 py-2">
              <Link
                to="/contact"
                className="d-flex align-items-center pointer text-decoration-none"
              >
                <div className="support-dd_icon expense d-flex align-items-center justify-content-center">
                  <img src={Contact} />
                </div>
                <div className="ml-3">
                  <div className="features-dd_title text-decoration-none pointer text-dark">
                    Contact
                  </div>
                  <div className="feature-dd_caption text-decoration-none pointer ">
                    reach out to our customer support for help
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportDd;
