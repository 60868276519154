import { Logo, NewLogo } from "../../assets/svgs";
import { colors } from "../../constants/color";
import "./style.css";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { useState } from "react";
const Modal = ({ closeModal }) => {
  const [featuremodal, setfeaturemodal] = useState(false);
  const BtnStyle = {
    borderRadius: "8px",
    color: "#fff",
    fontWeight: 500,
    fontSize: "14px",
    padding: "0px 40px",
    lineHeight: "21px",
    border: "transparent",
    outline: "1px solid #001755 !important",
    outlineColor: "#001755 !important",
    background: "#001755",
    height: "41px",
    border: "1px solid #001755",
  };
  return (
    <div className="modal-nav shadow" style={{}}>
      <div className="modal-container bg-white p-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className="d-flex align-items-center justify-content-center"
              style={
                {
                  // width: 60,
                  // height: 60,
                }
              }
            >
              <NewLogo className="" />
            </div>
            <h5
              className="fw-600 d-none fs-5 ml-2 pt-1"
              style={{
                color: colors.textColor,
              }}
            >
              Remis
            </h5>
          </div>
          <div className="cursor-pointer" onClick={() => closeModal(false)}>
            <IoMdClose size={28} color={colors.textColor} />
          </div>
        </div>
        {/* onClick={() => closeModal(false)} */}
        <div className="">
          <a href="/#why" className="pr-5 d-block py-2 my-3 text-dark">
            Why Remis
          </a>
          <div
            className=" py-2 my-3"
            onClick={() => setfeaturemodal(!featuremodal)}
          >
            <div className="d-flex align-items-center justify-content-between ">
              <div className="">Features</div>
              <div className="d-flex align-items-center justify-content-center">
                {featuremodal ? (
                  <RiArrowUpSLine size={30} />
                ) : (
                  <RiArrowDownSLine size={30} />
                )}
              </div>
            </div>
          </div>
          {featuremodal &&
            [
              { title: "Expense", link: "/expense", id: 1 },
              { title: "Trips", link: "/trips", id: 2 },
              { title: "Card", link: "/cards", id: 3 },
              { title: "Fleet", link: "/fleet", id: 4 },
            ].map(({ title, link, id }) => (
              <Link to={link} className="pr-5 py-2 my-3 text-dark" key={id}>
                {title}
              </Link>
            ))}
          <Link to="/" className="d-none pr-5 py-2 my-3">
            Blog
          </Link>
          <Link to="/" className="d-none pr-5 py-2 my-3">
            Support
          </Link>
        </div>
        <div className="mt-3">
          <a target={"_blank"} href="https://remis.africa/">
            <button style={BtnStyle}>Get Started</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
