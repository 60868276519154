import React from "react";
import { useEffect, useState } from "react";
// import ArrowUp from "../../assets/svgs/arrowup.svg";
import { colors } from "../../constants/color";
import { BsArrowUpShort } from "react-icons/bs";

function BackToTopBtn() {
  const [backToTopButton, setBackToTopButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {backToTopButton && (
        <button className='d-flex align-items-center justify-content-center p-4 ' style={{
          position:"fixed",
          bottom:"50px",
          right:"16px",
          height:"33px",
          width:"33px",
         zIndex:"100",
          borderRadius:"100px",
          background:"#001755",
          border:"#001755 2px solid"
      }} onClick={scrollUp}>
          <div className=''> 
          <BsArrowUpShort 
          size={30} color={colors.white} />
          </div>
  
      </button>
      )}
    </div>
  );
}

export default BackToTopBtn;
