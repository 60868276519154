import TripsImg from "../../assets/svgs/tripsimg.svg";
import ArrowRight from "../../assets/svgs/arrowupright.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackToTopBtn from "../../components/ToTop/BackToTopBtn";
const TripsSection = () => {
  const { t } = useTranslation();
  const [init, setInit] = useState(1);
  useEffect(() => {
    if (init == 4) {
      clearInterval(time);
      setInit(1);
      return;
    }
    var time = setInterval(() => {
      setInit((prev) => prev + 1);
    }, 3000);
    return () => clearInterval(time);
  }, [init]);

  return (
    <div className="position-relative">
      <BackToTopBtn/>
      {/* Section */}
      <div className="section-trips d-flex align-items-center py-5">
        <div className="container-fluid">
          <div className="row d-flex align-items-center justify-content-center ">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-3 my-sm-0 d-block d-sm-block d-md-block d-lg-block my-md-0  my-lg-0">
              <div className="trips-image_cover overflow-hidden d-flex align-items-center justify-content-center">
                <img src={TripsImg} alt={""} className="" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-3 my-sm-0  my-md-0  my-lg-0">
              <div className="px-2 px-sm-1 px-lg-0 px-md-0">
                <div className="mb-5 d-flex flex-column">
                  <div className="d-flex align-items-center ">
                    <div className="">
                      <h1
                        className={
                          init == 1
                            ? "animate__animated animate__fadeIn trip-title active"
                            : "animate__animated animate__fadeIn trip-title"
                        }
                        // className={
                        //   init == 1
                        //     ? "animate__animated animate__fadeIn trip-title active text-white"
                        //     : "trip-title animate__animated animate__fadeIn"
                        // }
                        dangerouslySetInnerHTML={{
                          __html: t("component.trips.row.one.title"),
                        }}
                      />
                      {/* {}
                      </h1> */}
                    </div>
                    <div
                      className=" ml-4 mt-2 palign-items-center justify-content-center d-none"
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    >
                      <img src={ArrowRight} alt="arrow" className="" />
                    </div>
                  </div>
                  <div
                    className={
                      init == 1
                        ? "animate__animated animate__fadeIn text-white"
                        : "animate__animated animate__fadeIn "
                    }
                    dangerouslySetInnerHTML={{
                      __html: t("component.trips.row.one.caption"),
                    }}
                  />
                  {/* Increase organisational incentives for your <br />
                    employees by managing and paying for their work-related
                    trips. */}
                  {/* </div> */}
                </div>
                <div className="">
                  <div
                    className={
                      init === 2
                        ? "trip-caption animate__animated animate__fadeIn text-white active py-2"
                        : "trip-caption py-2"
                    }
                    style={{}}
                  >
                    {t("component.trips.row.two.title")}
                  </div>
                  <div
                    className={
                      init == 2
                        ? "animate__animated animate__fadeIn text-white"
                        : "animate__animated animate__fadeIn "
                    }
                    dangerouslySetInnerHTML={{
                      __html: t("component.trips.row.two.caption"),
                    }}
                  />
                  {/* Create and fund trackable physical or virtual <br />
                    cards catered specifically to organisational unique needs. */}
                  {/* </div> */}
                  <div
                    className={
                      init === 3
                        ? "trip-caption animate__animated animate__fadeIn text-white active py-2"
                        : "trip-caption animate__animated animate__fadeIn py-2"
                    }
                    dangerouslySetInnerHTML={{
                      __html: t("component.trips.row.three.title"),
                    }}
                  />
                  {/*                     
                  </div> */}
                  <div
                    className={
                      init == 3
                        ? "animate__animated animate__fadeIn text-white"
                        : "animate__animated animate__fadeIn "
                    }
                    dangerouslySetInnerHTML={{
                      __html: t("component.trips.row.three.caption"),
                    }}
                  >
                    {}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripsSection;
