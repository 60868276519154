import { Cards, RemisCardB, RemisCardP, RemisCardT } from "../../assets/svgs";
import PhoneMock from "../../assets/images/phone-mock.png";
import "./index.css";
import CardsVideo from "../../assets/video/cards.mp4";
import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { Link, useHistory } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { colors } from "../../constants/color";
import { GoPrimitiveDot } from "react-icons/go";
import { useTranslation } from "react-i18next";

function ExpenseCards() {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const card = useRef();
  const tween1 = gsap.fromTo(
    ".black",
    {
      y: -120,
      opacity: 0,
      ease: "back",
    },
    {
      y: 0,
      opacity: 1,
      delay: 2,
      duration: 2,
    }
  );
  const tween2 = gsap.fromTo(
    ".white",
    {
      y: -120,
      opacity: 0,
      ease: "back",
    },
    {
      y: 30,
      opacity: 1,
      delay: 1,
      duration: 2,
    }
  );
  const tween3 = gsap.fromTo(
    ".blue",
    {
      y: -120,
      opacity: 0,
      ease: "back",
    },
    {
      y: 80,
      opacity: 1,
      duration: 2,
    }
  );

  const [intersecting, setIntersecting] = useState(false);
  const observer = new IntersectionObserver(
    (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIntersecting(entry.isIntersecting);
      }
    },
    {
      threshold: 0.3,
    }
  );

  useEffect(() => {
    observer.observe(card.current);
    if (intersecting) {
      tween1.play();
      tween2.play();
      tween3.play();
    }
    return () => {
      // observer.unobserve(card.current);
    };
  }, [intersecting]);

  return (
    <section id="cards" className="my-1">
      <div
        className="rm-container bg-white overflow-hidden mx-auto card-section mt-5"
        ref={card}
        style={{
          width: "90%",
          border: "2px solid #EAF0FF",
          borderRadius: 10,
        }}
      >
        <div className="row d-flex flex-row-reverse flex-sm-row-reverse flex-md-row align-items-center justify-content-between justify-content-md-center ">
          <div className="col-sm-12 col-md-11 col-lg-6 p-sm-3 p-md-5 p-lg-5">
            <div
              className="d-flex remis-card-cover justify-content-center align-items-center position-relative"
              style={{
                width: "100%",
                height: 400,
              }}
            >
              {/* <Cards /> */}
              <RemisCardT className="blue remis-card" />
              <RemisCardB className="black remis-card " />
              <RemisCardP className="white remis-card" />
            </div>
          </div>
          <div className="col-sm-12 col-md-11 col-lg-6 p-5">
            <div className="lead-secondary text-left text-sm-left text-md-left  text-lg-left">
              {t("landing.cards.title")}
            </div>
            <h2 className="pt-3 manage-lead text-left text-sm-left text-md-left  text-lg-left">
              {t("landing.cards.caption")}
            </h2>
            <div className="heading-body-p w-100 plus-jarkata text-sm-left text-md-center text-lg-left w-75 pt-2 text-left text-sm-left text-md-center text-lg-left">
              {t("landing.cards.desc")}
            </div>
            <div className="pt-3 plus-jarkata">
              <div className="w-100 d-flex align-items-center justify-content-start justify-content-sm-start justify-content-md-center justify-content-lg-start pb-3">
                <div className=" mr-3">
                  <GoPrimitiveDot color="#d9d9d9" size={25} />
                </div>
                <div className="heading-body-p">
                  {t("landing.cards.bulletOne")}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-start justify-content-sm-start justify-content-md-center justify-content-lg-start pb-3">
                <div className=" mr-3">
                  <GoPrimitiveDot color="#d9d9d9" size={25} />
                </div>
                <div className="heading-body-p">
                  {t("landing.cards.bulletTwo")}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-start justify-content-sm-start justify-content-md-center justify-content-lg-start pb-3">
                <div className=" mr-3">
                  <GoPrimitiveDot color="#d9d9d9" size={25} />
                </div>
                <div className="heading-body-p">
                  {t("landing.cards.bulletThree")}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Link
                to="/cards"
                className="text-dark-rm .text-dark-rm:hover  pointer text-start text-decoration-none page-links"
              >
                <div className="text-decoration-none">
                  {t("landing.cards.btn")}
                </div>
                <div className="">
                  <MdKeyboardArrowRight size={20} color={colors.textColor} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExpenseCards;

{
  /* <video
autoPlay
loop
width={"100%"}
style={{
  height: 500,
  scale: 2,
}}
>
<source src={CardsVideo} type="video/mp4" />
</video> */
}
