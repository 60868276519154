import React, { useEffect } from "react";
import Spend from "../../assets/svgs/spend.svg";
import { gsap } from "gsap";
import "./index.css";
import DoughnutChart from "../../components/Chart/DoughnutChart";
import { Link, useHistory } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { colors } from "../../constants/color";
import FlowImg from "../../assets/images/Frame 3330.png";
import { useTranslation } from "react-i18next";
function Expense() {
  const history = useHistory();
  useEffect(() => {
    gsap.fromTo(
      ".pill-cover1",
      {
        scrollTrigger: ".chart-area", // start the animation when ".box" enters the viewport (once)
        y: 0,
        opacity: 0,
        rotate: 30,
      },
      {
        y: 360,
        ease: "back",
        duration: 5,
        opacity: 1,
        delay: 2,
        rotate: 13,
      }
    );
    gsap.fromTo(
      ".pill-cover2",
      {
        scrollTrigger: ".chart-area", // start the animation when ".box" enters the viewport (once)
        y: 0,
        opacity: 0,
        rotate: -30,
      },
      {
        y: 360,
        ease: "back",
        duration: 5,
        opacity: 1,
        delay: 3,
        rotate: -9,
      }
    );
    gsap.fromTo(
      ".pill-cover3",
      {
        scrollTrigger: ".chart-area", // start the animation when ".box" enters the viewport (once)
        y: 0,
        x: 30,
        opacity: 0,
        rotate: -26,
      },
      {
        y: 290,
        ease: "back",
        duration: 5,
        opacity: 1,
        delay: 4,
        rotate: -13,
        x: 60,
      }
    );
    gsap.fromTo(
      ".pill-cover4",
      {
        scrollTrigger: ".chart-area", // start the animation when ".box" enters the viewport (once)
        y: 0,
        x: 20,
        opacity: 0,
        rotate: 26,
      },
      {
        y: 340,
        ease: "back",
        duration: 5,
        x: 40,
        opacity: 1,
        delay: 4,
        rotate: 5,
      }
    );
    gsap.fromTo(
      ".pill-cover5",
      {
        scrollTrigger: ".chart-area", // start the animation when ".box" enters the viewport (once)
        y: 0,
        opacity: 0,
        rotate: -18,
      },
      {
        y: 390,
        ease: "back",
        duration: 5,
        opacity: 1,
        delay: 4,
        rotate: -5,
      }
    );
    return () => {};
  }, []);
  const { t, i18n } = useTranslation();
  return (
    // <div
    //   className="mx-auto px-2 container bg-white p-lg-5 p-md-3 p-sm-1 expense-section"
    //   style={{
    //     border: "2px solid #EAF0FF",
    //     borderRadius: 10,
    //   }}
    // >
    <div
      className="mx-auto expense-section overflow-hidden bg-white mt-5"
      style={{
        border: "2px solid #EAF0FF",
        borderRadius: 10,
        width: "90%",
      }}
    >
      <div className="row  px-0 px-sm-0 px-md-3 px-lg-5 d-flex align-items-center justify-content-between justify-content-md-center justify-content-sm-center justify-content-lg-between">
        <div className="col-sm-12 col-md-10 col-lg-5 col-xl-5 px-2 d-flex align-items-center justify-content-center my-3">
          <div className="px-2 px-sm-4">
            <div className="pl-3 lead-secondary text-left text-sm-left text-md-left text-lg-left">
              {t("landing.expenseMgt.title")}
            </div>
            {/* <h2 className="pt-3 pl-3 manage-lead text-sm-left text-md-left text-lg-left text-xl-left ">
              Manage Business Expenses,
              <br /> Anywhere
            </h2> */}
            <h2
              className="pt-3 pl-3 manage-lead text-sm-left text-md-left text-lg-left text-xl-left "
              dangerouslySetInnerHTML={{
                __html: `${t("landing.expenseMgt.caption")}`,
              }}
            />
            <div
              className="text-rm-dark pt-3 pl-3 text-sm-left text-md-left text-lg-left text-xl-left"
              style={{}}
            >
              {/* { Monitor your business spending and cash flow from anywhere. Get
              your hands on your financial performance by managing your business
              expenses, and financial assets. Lose no time to bureaucracies and
              time-consuming delays. Empower your employees to raise
              requisitions and accelerate authorised disbursements with ease.} */}
              {t("landing.expenseMgt.desc")}
            </div>
            <div className="mt-5 pt-2 pl-3 d-flex w-100 align-items-center justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xl-start ">
              <Link
                to="/expense"
                className="text-dark-rm .text-dark-rm:hover  pointer text-start text-decoration-none page-links"
              >
                <div className="text-decoration-none">
                  {t("landing.expenseMgt.btn")}
                </div>
                <div className="">
                  <MdKeyboardArrowRight size={20} color={colors.textColor} />
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-sm-10 col-md-9 col-lg-5 col-xl-5 p-md-5 ">
          <div className="w-100 pt-2pt-lg-5 pt-md-0 overflow-visible pl-sm-4 my-3 px-3 ">
            <div className="text-center  pb-3 text-sm-left fs-4 text-md-center text-lg-center">
              {/* {t("landing.expenseMgt.helperText")} */}
            </div>
            <div className="d-none">
              <div
                className="position-relative w-100 d-flex align-items-center justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-start "
                style={{
                  width: 180,
                  height: 180,
                }}
              >
                <div
                  className="spend-portion d-none position-absolute uiux"
                  style={{
                    top: "40%",
                  }}
                >
                  <div
                    className="text-center"
                    style={{
                      color: "#ddd",
                      fontSize: 13,
                    }}
                  >
                    UI/UX
                  </div>
                  <div className="text-center fw-bold">50,000,000.00</div>
                </div>
                <DoughnutChart />
              </div>
              <div className="row w-100 ">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center my-3">
                    <div
                      className="rounded"
                      style={{
                        width: 23,
                        height: 23,
                        background: "#099268",
                      }}
                    ></div>
                    <div className="budget-title pl-3">
                      60% percent spent by UI/UX.
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center my-3">
                    <div
                      className="rounded "
                      style={{
                        width: 23,
                        height: 23,
                        background: "#ffc68f",
                      }}
                    ></div>
                    <div className="budget-title pl-3">
                      10% percent spent by frontend.
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center my-3">
                    <div
                      className="rounded "
                      style={{
                        width: 23,
                        height: 23,
                        background: "#aa99df",
                      }}
                    ></div>
                    <div className="budget-title pl-3">
                      20% percent spent by field workers.
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center my-3">
                    <div
                      className="rounded "
                      style={{
                        width: 23,
                        height: 23,
                        background: "#7986a6",
                      }}
                    ></div>
                    <div className="budget-title pl-3">
                      20% percent spent by others.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                height: 280,
              }}
            >
              <img
                src={FlowImg}
                loading="lazy"
                alt="flow image"
                className="w-100 h-100 img-fluid"
              />
            </div>

            <div className="pill-cover1 d-none">
              <div className="pill">Reconcilation</div>
            </div>
            <div className="pill-cover2 d-none">
              <div className="pill">Reimbursement</div>
            </div>
            <div className="pill-cover3 d-none">
              <div className="pill">Budgeting</div>
            </div>
            <div className="pill-cover4 d-none">
              <div className="pill">Approval workflow</div>
            </div>
            <div className="pill-cover5 d-none">
              <div className="pill">Requisition</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expense;
