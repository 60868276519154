import { CardIcon, Puzzle, Stock } from "../../assets/svgs";
import "./index.css";
import { colors } from "../../constants/color";
import { chargeData } from "./data";
function TakeCharge() {
  return (
    <div className="" id="why">
      <section className="take-charge-part bg-white">
        <h2
          className="lead-body-text pt-5"
          style={{
            color: colors.blacktext,
          }}
        >
          Take charge of your business <br /> finances with Remis
        </h2>
        <div className="row p-2 p-sm-2 justify-content-start p-lg-5 charge-section mx-auto plus-jarkata  ">
          {chargeData.map(({ id, title, desc, tag }, index) => (
            <div
              className="col-sm-12 col-md-6 col-lg-4 col-xl-4 my-3"
              key={index}
              data-aos="fade-up"
              // data-aos-anchor-placement="top"
              data-aos-duration="800"
            >
              <div
                className={`p-3 p-sm-2 p-md-2 p-lg-2 ${
                  id == 1 || id == 2 ? "right-dashed" : ""
                }`}
                style={{
                  height: 250,
                }}
              >
                <div className="pb-3">
                  {id == 1 ? <Puzzle /> : id == 2 ? <CardIcon /> : <Stock />}
                </div>
                <div
                  className={`heading-p-sm pb-2 ${
                    id == 2 ? "pt-3" : id == 3 ? "pt-2" : ""
                  }`}
                >
                  {title}
                </div>
                <div className="heading-body-p w-100">{desc}.</div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default TakeCharge;
