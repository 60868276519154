import {
  CardIcon,
  FundTransfer,
  Cards,
  Station,
  Comp,
  AutoMobile,
  AppleDownload,
  LogoWhite,
  PlayDownload,
  Nuzzle,
  BrowserFrame,
} from "../../assets/svgs";
import PhoneMock from "../../assets/images/phone-mock.png";

import "./index.css";
import AltCard from "../../assets/images/alt-card.png";
import Frame1 from "../../assets/images/frame1.png";
import Frame4 from "../../assets/images/frame4.png";
import twitter from "../../assets/images/twitterrm.png";
import fb from "../../assets/images/facebuk.png";
import instamain from "../../assets/svgs/insta-main.svg";
import MockupAuto from "../../assets/images/mockup-auto.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer mt-5">
      <div className="">
        <div className="row">
          <div className="col-md-3">
            <Link to="/">
              <LogoWhite />
            </Link>
            <br />
            <div className="footer-lead">{t("footer.title")}</div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-3 mt-3 mt-sm-1 mt-md-0">
                <div className="head-footer-text">
                  {t("footer.company.title")}
                </div>
                <div className="sub-footer-text">
                  <Link to="/" className="d-block text-white">
                    {t("footer.company.links.one")}
                  </Link>
                  <br />
                  <Link to="/" className="d-block text-white">
                    {t("footer.company.links.two")}
                  </Link>
                  <br />
                  <Link to="/" className="d-block text-white">
                    {t("footer.company.links.three")}
                  </Link>
                </div>
              </div>
              <div className="col-md-3 mt-3 mt-sm-1 mt-md-0">
                <div className="head-footer-text">
                  {t("footer.product.title")}
                </div>
                <div className="sub-footer-text">
                  <Link to="/expense" className="d-block text-white">
                    {t("footer.product.links.one")}
                  </Link>
                  <br />
                  {/* <a
                    href="https://app.remis.africa/"
                    target={"_blank"}
                    className="d-block text-white"
                  >
                    {t("footer.product.links.two")}
                  </a> */}
                   <Link to="/cards" className="d-block text-white">
                    {t("footer.product.links.two")}
                  </Link>
                  <br />
                  <Link to="/trips" className="d-block text-white">
                    {t("footer.product.links.three")}
                  </Link>
                  <br />
                  <Link to="/fleet" className="d-block text-white">
                    {t("footer.product.links.four")}
                  </Link>
                </div>
              </div>
              <div className="col-md-3 mt-3 mt-sm-1 mt-md-0">
                <div className="head-footer-text">
                  {t("footer.learn.title")}
                </div>
                <div className="sub-footer-text">
                <a
                    href={"https://remisafrica.medium.com/"}
                    className="d-block text-white "
                    target={"_blank"}
                  >
                    {" "}
                    {t("footer.learn.links.one")}
                  </a>
                  <br />
                  <Link to="/faq" className="d-block text-white">
                    {t("footer.learn.links.two")}
                  </Link>
                  
                  
                  <br />
                  <Link to="/" className="d-block text-white">
                    {/* {t("footer.learn.links.three")} */}
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="head-footer-text d-block">
                  {t("footer.contact.title")}
                </div>
                <div className="sub-footer-text">
                  <a
                    href="mailto:Info@remis.africa"
                    className="d-block text-white"
                  >
                    {t("footer.contact.links.one")}
                  </a>
                  <Link to="/contact" className="d-block mt-4 text-white">
                    {t("footer.contact.links.two")}
                  </Link>
                  <br />
                  <div className="d-flex align-items-center">
                    <a href={"https://instagram.com/remisafrica"}
                     target={"_blank"}
                    >
                    <img src={instamain} width="35" className="pr-3" />
                    </a>
                   <a href="https://twitter.com/remisafrica" target={"_blank"}>
                   <img src={twitter} width="35" className="pr-3" />
                   </a>
                    
                    <a href="https://www.facebook.com/remisafrica">
                    <img src={fb} width="35" className="pr-3" />
                    </a>
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
