import {
  CardIcon,
  FundTransfer,
  Cards,
  Station,
  Comp,
  AutoMobile,
  AppleDownload,
  LogoWhite,
  PlayDownload,
  Nuzzle,
  BrowserFrame,
} from "../../assets/svgs";
import "./index.css";
import { useTranslation } from "react-i18next";
function Decisions() {
  const { i18n, t } = useTranslation();
  return (
    <section
      className="px-2 decision-container my-5  d-flex align-items-center justify-content-center "
      style={{
        height: "70vh",
      }}
    >
      <div className="d-flex flex-column justify-center" data-aos="fade-up">
        <h2 className="lead-body-text">
          Make smart financial decisions <br /> with{" "}
          <span style={{ color: "#001755" }}>Remis</span>
        </h2>
        <div className=" plus-jarkata">
          {/* text-caption text-center pt-2 */}
          <div
            className="text-center"
            style={{
              fontSize: 18,
            }}
            dangerouslySetInnerHTML={{ __html: t("landing.decision.desc") }}
          >
            {/* Reconcile expenses, track spending, and monitor your company’s
            financial <br /> performance in real-time with our cutting-edge
            technology and intuitive <br /> spend management platform. */}
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="pt-4 d-flex justify-content align-content-center">
            <a
              href="https://apps.apple.com/ng/app/remis-africa/id6443777963"
              target={"_blank"}
            >
              <AppleDownload className="mr-3" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=africa.remis.app&hl=en&gl=US"
              target={"_blank"}
            >
              <PlayDownload />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Decisions;
