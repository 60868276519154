import Header from "../../components/Header/index";
import "./index.css";
import { Footer } from "../../components/Footer/Footer";
import cron from "../../assets/images/cron.svg";
import cron2 from "../../assets/images/cron2.png";
import storyimg from "../../assets/images/storyimg.png";
import person2 from "../../assets/images/first-sqaure.png";
import Community from "../../components/PageComponents/Community";
import React, { useEffect, useState } from "react";
import BackToTopBtn from "../../components/ToTop/BackToTopBtn";
import NavBar from "../../components/NavBar";
import { Helmet } from "react-helmet";
import LadyLaptop from "../../assets/images/ladyLaptopmain.png";
import PriceValue from "../../assets/images/pricevalue.png";
import YellowLogo from "../../assets/svgs/yellowlogo.svg";
import Young from "../../assets/images/young.png";
import IPhomeImg from "../../assets/images/[Mockup] iPhone 14.png";
import { useTranslation } from "react-i18next";
import Lines from "../../assets/images/Vector.png";
import { DesktopBg, Logo, PlayIcon } from "../../assets/svgs/index";
import Decisions from "../Landing/Decisions";
import AboutUs from "./aboutUs";
import OurMission from "./ourMission";
import OurStory from "./ourStory";
import CoreValues from "./coreValues";
function About() {
  // const [stage1, setStage1] = useState(true);
  // const [stage2, setStage2] = useState(false);
  // const [stage3, setStage3] = useState(false);

  // const gotoStage1 = () => {
  //   setStage1(true);
  //   setStage2(false);
  //   setStage3(false);
  // };

  // const gotoStage2 = () => {
  //   setStage1(false);
  //   setStage2(true);
  //   setStage3(false);
  // };

  // const gotoStage3 = () => {
  //   setStage1(false);
  //   setStage2(false);
  //   setStage3(true);
  // };
  const { i18n, t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Remis :: Contact us</title>
      </Helmet>
      <NavBar />
      <AboutUs />
      <BackToTopBtn />

      <OurMission />

      {/* <div className="mt-5 pt-5">
          <div>
            <div className="fore-dark-border-bg">
              <div className="row">
                <div className="col-md-6 col-12 ">
                  <div className="d-flex ">
                    <div className="mr-5">
                      <div className="d-flex align-items-center flex-column">
                        <div
                          className={`mb-5 ${
                            stage1
                              ? "vertical-carousel-active"
                              : "vertical-carousel"
                          }`}
                          onClick={gotoStage1}
                        ></div>
                        <div
                          className={`mb-5 ${
                            stage2
                              ? "vertical-carousel-active"
                              : "vertical-carousel"
                          }`}
                          onClick={gotoStage2}
                        ></div>
                        <div
                          className={`mb-5 ${
                            stage3
                              ? "vertical-carousel-active"
                              : "vertical-carousel"
                          }`}
                          onClick={gotoStage3}
                        ></div>
                      </div>
                    </div>
                    {stage1 && (
                      <div>
                        <div
                          className="orange-text-medium"
                          style={{ fontWeight: "300" }}
                        >
                          Our mission
                        </div>
                        <h3 className="pt-4 text-white font-weight-bold">
                          Building a community <br />
                          that understands value
                        </h3>
                        <br />
                        <div className="text-white" style={{ opacity: 1 }}>
                          <div>
                            To be the leading choice for individuals and
                            businesses looking for the best out of every
                            experience.
                          </div>
                          <div>
                            {" "}
                            Providing unparalleled service with a sprinkle of
                            finesse and exclusivity that meets and even
                            surpasses the expected return for every amount
                            spent.
                          </div>{" "}
                          <div>
                            Availing every individual the opportunity to
                            experience what being a high net worth individual
                            feels like, regardless of their current status in
                            society.
                          </div>{" "}
                          <div>
                            {" "}
                            Then I discussed my options with my husband and some
                            friends. I ultimately chose the original version -
                            there are also many versions in different scents,
                            oils, and a couple of powders.
                          </div>
                          <br />{" "}
                          <div>
                            {" "}
                            Being the go-to for authenticity when it comes to
                            products and services.
                          </div>{" "}
                          <br />
                          <br />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12 hide-mobile">
                  <div class="outer circle-concentric">
                  <div class="middle circle-concentric">
                    <div class="inner circle-concentric"></div>
                  </div>
                </div>
                  <div
                    className="d-flex justify-content-center align-items-start"
                    style={{ height: "100%" }}
                  >
                    <img src={person} width="200" />
                    <div className="below-pic">
                      <img src={person2} width="200" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className="">
          <div>
            <div className="bg-white ourstory-section">
              <div className="row align-items-center position-relative ">
                <div
                  className="position-absolute  d-none d-sm-none d-md-none d-lg-block"
                  style={{
                    left: 10,
                    bottom: 0,
                  }}
                >
                  <img
                    src={Lines}
                    alt={"lines"}
                    className="img-fluid w-100 h-100"
                    loading="lazy"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 position-relative my-3 my-sm-0  my-md-0  my-lg-0  ">
                  <div className="d-flex justify-content-center ">
                    <div className=" pt-4">
                      <h2
                        className=""
                        style={{
                          fontFamily: "Quiet Sans",
                          fontSize: " 48px",
                          fontWeight: 600,
                          lineHeight: " 62px",
                          letterSpacing: "-2px",
                          textAlign: "justify",
                        }}
                      >
                        Our Story
                      </h2>
                      <p className="ourstory-text">
                        Lorem ipsum dolor sit amet, consectetur <br />{" "}
                        adipiscing elit. Pellentesque scelerisque, lacus <br />{" "}
                        id tempus consectetur, lorem felis finibus <br />{" "}
                        turpis, id posuere arcu urna et nulla. Sed ut <br />{" "}
                        pharetra turpis, tempus cursus lacus. Proin in <br />{" "}
                        est augue. Nunc pulvinar lorem mauris, non
                        <br />
                        feugiat sapien egestas at. In commodo <br /> condimentum
                        lacus,
                      </p>

                      <p className="ourstory-text">
                        Lorem ipsum dolor sit amet, consectetur <br />{" "}
                        adipiscing elit. Pellentesque scelerisque, lacus <br />{" "}
                        id tempus consectetur, lorem felis finibus <br />{" "}
                        turpis, id posuere arcu urna et nulla. Sed ut <br />{" "}
                        pharetra turpis, tempus cursus lacus. Proin in <br />{" "}
                        est augue. Nunc pulvinar lorem mauris, non
                        <br />
                        feugiat sapien egestas at. In commodo <br /> condimentum
                        lacus,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 hide-mobile">
                  <div className="d-flex justify-content-center align-items-start">
                    <img
                      className=""
                      src={storyimg}
                      style={{
                        height: "620px",
                        width: "525px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      <OurStory />

      <CoreValues />
      <Decisions />
      <Footer />
    </div>
  );
}

export default About;
