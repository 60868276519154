import {
  CardIcon,
  FundTransfer,
  Cards,
  Station,
  Comp,
  AutoMobile,
  AppleDownload,
  LogoWhite,
  PlayDownload,
  Nuzzle,
  BrowserFrame,
  BrowserFrame1,
} from "../../assets/svgs";
import StationImage from "../../assets/svgs/station.svg";
import BFrame from "../../assets/images/bframe.png";
import FleetMgt from "../../assets/images/Browser Frame (2).png";
import FleetCard from "../../assets/images/Black-remis-card.png";
import FleetTrips from "../../assets/images/[Mockup] iPhone 13 (1).png";
import MockupAuto from "../../assets/images/mockup-auto.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowRight } from "react-icons/md";
import { colors } from "../../constants/color";

function FleetManagement() {
  const { t } = useTranslation();
  return (
    <section
      className="mx-auto fleet"
      style={{
        width: "90%",
      }}
    >
      <div className="">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 overflow-hidden">
            <div
              className="expense-management-bottom p-4 p-sm-4 p-md-5 bg-white mt-5 position-relative overflow-hidden "
              style={{
                border: "2px solid #EAF0FF",
                borderRadius: 10,
                overflow: "hidden",
              }}
            >
              <div className="lead-secondary">{t("landing.fleet.title")}</div>
              <h2 className="pt-3 manage-lead  ">
                {t("landing.fleet.caption")}
              </h2>
              <div className="heading-body-p w-75 pt-2 w-100 plus-jarkata ">
                {t("landing.fleet.desc")}
              </div>
              <div className="mt-4 d-flex align-items-center justify-content-start">
                <Link
                  to={"/fleet"}
                  className="text-dark-rm pointer d-inline text-start text-decoration-none cursor-pointer "
                >
                  {t("landing.fleet.btn")}
                  <MdKeyboardArrowRight size={20} color={colors.textColor} />
                </Link>
              </div>
              <div className="container-fluid bottom-explore-image w-100 d-none">
                <div className="row ">
                  <div className="col-5 position-relative">
                    <div
                      className="d-flex align-items-center justify-content-center position-absolute overflow-hidden rounded-lg"
                      style={{
                        width: "100%",
                        height: 200,
                        bottom: -100,
                      }}
                    >
                      {/* <Station width={"100%"} className="station-image" /> */}
                      <img
                        src={FleetCard}
                        alt=""
                        className="img-fluid w-100 h-100 "
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div
                    className="col-6 position-relative"
                    style={{
                      height: 120,
                    }}
                  >
                    <img
                      src={FleetMgt}
                      alt=""
                      className="position-absolute w-100 h-100 "
                      loading="lazy"
                    />
                    {/* <BrowserFrame1
                      width={"100%"}
                      className="fleet-image__cover position-absolute"
                    /> */}
                  </div>
                </div>
              </div>
              <div
                className="row position-relative d-flex justify-content-md-between justify-content-lg-between align-items-end "
                style={{
                  bottom: -10,
                }}
              >
                <div
                  className="col-sm-12 mt-3 col-md-3 col-lg-3 col-xl-3 position-relative d-none d-sm-none d-md-none d-lg-block"
                  style={{
                    bottom: -40,
                  }}
                >
                  <div
                    className="w-100 rounded-lg overflow-hidden"
                    style={{
                      height: 300,
                      bottom: -100,
                    }}
                  >
                    <img
                      src={FleetCard}
                      alt=""
                      className="img-fluid w-100 h-100 "
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 p-0 col-md-12 col-lg-9 col-xl-9 col-xxl-9 mt-5 w-100 d-flex align-items-center justify-content-center position-relative ">
                  <div
                    className="w-100"
                    style={
                      {
                        // height: 300,
                      }
                    }
                  >
                    <img
                      src={FleetMgt}
                      alt=""
                      className="img-fluid w-100 h-100 "
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div
              className="expense-management-view-sm p-3 p-sm-4 p-md-5 mt-5 "
              style={{
                border: "2px solid #EAF0FF",
                borderRadius: 10,
                overflow: "hidden",
              }}
            >
              <div className="lead-secondary">
                {t("landing.fleet.trips.title")}
              </div>
              <h2 className="pt-3 manage-lead">
                {t("landing.fleet.trips.caption")}
              </h2>
              <div className="heading-body-p pt-2 w-100 plus-jarkata">
                {t("landing.fleet.trips.desc")}
              </div>
              <div className="mt-4">
                <Link
                  to="/trips"
                  className="text-dark-rm d-block text-decoration-none "
                  style={{
                    textAlign: "start",
                  }}
                >
                  {t("landing.fleet.trips.btn")}
                  <MdKeyboardArrowRight size={20} color={colors.textColor} />
                </Link>
              </div>
              <br />
              <div className="row d-flex align-items-center justify-content-center ">
                <div
                  className="col-sm-10 col-md-6 col-lg-12 col-xl-12"
                  style={{
                    height: 250,
                  }}
                >
                  <img src={FleetTrips} className="img-fluid w-100 h-100" />
                </div>
                {/* <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-5">
                    <div className="" style={{}}>
                      <AutoMobile className="auto-mobile" />
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <div className="mockup-auto__cover d-none">
                <img
                  src={MockupAuto}
                  width="100%"
                  className="img-fluid w-100 h-100"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FleetManagement;

{
  /* <div className="d-none">
</div> */
}
