import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "../views/Landing/index";
import About from "../views/About/index";
// import Contact from "../views/Contact/index";
import Community from "../views/Communnity/index";
import Policy from "../views/Privacy/policy";
import "../App.css";
import ExpenseManagement from "../views/ExpenseManagement";
import { Logo } from "../assets/svgs";
import { gsap } from "gsap";

// import screens
const Landing1 = lazy(() => import("../views/Landing/index"));
const Expense = lazy(() => import("../views/ExpenseManagement"));
const Cards = lazy(() => import("../views/Cards"));
const Contact = lazy(() => import("../views/Contact"));
const TripScreen = lazy(() => import("../views/Trips"));
const FAQ = lazy(() => import("../views/FAQ"));
const Fleet = lazy(() => import("../views/Fleet"));
const Blog = lazy(() => import("../views/Blog"));
// const About = lazy(() => import("../views/About"));

export default function AppRouter(props) {
  useEffect(() => {
    gsap.fromTo(
      ".scale",
      {
        scale: 0.5,
      },
      {
        scale: 1,
        duration: 3,
      }
    );

    return () => {};
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <div
            className=""
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="">
              <Logo className="scale" />
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/" exact component={Landing1} />
          <Route path="/expense" exact component={Expense} />
          <Route path="/cards" exact component={Cards} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/trips" exact component={TripScreen} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/fleet" exact component={Fleet} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/contact-us" exact component={Contact} />
          <Route path="/about" exact component={About} />
          {/* <Route path="/community" exact component={Community} />
        <Route path="/privacy-policy" exact component={Policy} />  */}
        </Switch>
      </Suspense>
    </Router>
  );
}
