import {
  DesktopBg,
  PhoneMock,
  PlayIcon,
  Calc,
  ExpenseHeroImgNew,
} from "../../assets/svgs";
import { Button } from "../../components/Button";
import Footer from "../../components/Footer/Footer";
import "./index.css";
import React, { useEffect, useRef, useState } from "react";
import Frame from "../../assets/images/Frame.png";
import NavBar from "../../components/NavBar";
import CalcImage from "../../assets/svgs/calc.svg";
import ExpenseImg1 from "../../assets/svgs/expenseimg1.svg";
import ExpenseImg2 from "../../assets/svgs/expenseimg2.svg";
import ExpenseImg3 from "../../assets/images/Rectangle 1558.png";
import TripsImg from "../../assets/svgs/tripsimg.svg";
import ExpenseHeroImg from "../../assets/svgs/expensehero.svg";
import ArrowImg from "../../assets/svgs/expensearrow.svg";
import Sparkles from "../../assets/svgs/sparkle.svg";
import Decisions from "../Landing/Decisions";
import TripsSection from "../../components/Trips";
import NewApprovalImg from "../../assets/svgs/newApprovalImg.svg";
import BgWrapper from "../../assets/images/herobg.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NewPieChart from "../../assets/svgs/newPieChart.svg";
import Cron from "../../assets/images/cron2.png";
import { useTranslation } from "react-i18next";
import BackToTopBtn from "../../components/ToTop/BackToTopBtn";

const ExpenseManagement = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    return () => {};
  }, []);
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Remis :: Expense</title>
      </Helmet>
      <NavBar />
      <BackToTopBtn />
      <div className="card-section">
        <div
          className="container-fluid overflow-hidden section-expense_bg"
          style={{
            paddingTop: 100,
          }}
        >
          <div
            className="p-md-2 p-lg-5 rounded-3 px-3 mx-auto shadow-sm section-bg_wrapper"
            style={{}}
          >
            <div
              className="row d-flex justify-content-sm-center align-items-start justify-content-md-center justify-content-lg-between "
              style={{}}
            >
              <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 animate__animated animate__fadeInLeft">
                <div className="h-100 my-5 ">
                  <div className="lead-text text-white">
                    {t("expense.hero.title")}
                  </div>
                  <br />
                  <div className="lead-light-text text-white">
                    {t("expense.hero.caption")}
                  </div>

                  {/* button email view */}
                  <div className="row pt-5 flex justify-content-between align-items-center w-100 d-none d-md-none d-lg-flex ">
                    <div className="col-8">
                      <div className="d-flex justify-content align-items-center w-100">
                        <input
                          className="input-email__cover w-100 px-4"
                          placeholder="Enter your work email "
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <a
                        className="w-100 d-flex align-items-center justify-content-center"
                        href={"https://app.remis.africa/Signup"}
                        target={"_blank"}
                      >
                        <Button
                          text="Get started"
                          style={{
                            height: "53px",
                            fontSize: 14,
                            width: "166px",
                            background: "#FAA004",
                          }}
                          className="dark"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* left */}
              <div className="col-sm-12 my-2 pb-2 my-sm-2 my-md-0 col-md-10 col-lg-5 col-xl-5 d-block d-sm-block d-md-block d-lg-block justify-content-center animate__animated animate__fadeInRight position-relative">
                <div
                  className="w-100 h-100 position-relative d-flex align-items-center justify-content-center"
                  style={{
                    height: 380,
                  }}
                >
                  <ExpenseHeroImgNew />
                  {/* <img src={Frame} className="w-100 h-100 img-fliud" /> */}
                </div>
                <div className="expense-cron d-none position-absolute">
                  <img src={Cron} alt="" className="w-100 h-1000" />
                </div>
                {/* button email view */}
                <div className="row pt-2 w-100 justify-content-center align-items-center w-100 d-md-flex d-lg-none p-0 m-0">
                  <div className="col-12 col-sm-12 col-md-8 col-lg-7 my-2">
                    <div className="d-flex justify-content align-items-center w-100">
                      <input
                        className="input-email__cover w-100 px-4"
                        placeholder="Enter your work email "
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-7 my-2">
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <a
                        className="w-100 d-block text-decoration-none d-flex align-items-center justify-content-center"
                        href={"https://app.remis.africa/Signup"}
                        target={"_blank"}
                      >
                        <Button
                          text="Get started"
                          style={{
                            height: "53px",
                            width: "166px",
                            background: "#FAA004",
                          }}
                          className="dark "
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="expense-hero_image d-none position-absolute"
                  style={{
                    top: 400,
                    left: 200,
                  }}
                >
                  <img alt="" width={"100%"} height={"100%"} className="" />
                </div>
              </div>
              {/* Mobile image views */}
              <div className="col-12 d-none mt-5 mt-sm-0 mt-md-0 mt-lg-0 ">
                {/* <div className="col-12 d-none mt-5 mt-sm-0 mt-md-0 mt-lg-0 d-sm-block d-md-none overflow-hidden position-relative justify-content-end"> */}
                <div
                  className="mobile-dskt_design position-relative"
                  style={{
                    width: "100%",
                    height: 500,
                  }}
                >
                  <div
                    className="position-absolute pt-5 w-100"
                    style={{
                      top: -30,
                    }}
                  >
                    <img
                      src={Frame}
                      className="img-fluid"
                      alt="desktop image"
                      loading="lazy"
                      style={{
                        scale: 0.7,
                        height: 400,
                      }}
                    />
                  </div>
                  <div
                    className="position-absolute pt-5 w-100"
                    style={{
                      right: -180,
                      bottom: -10,
                    }}
                  >
                    <img
                      src={ExpenseHeroImg}
                      className="img-fluid"
                      alt="desktop image"
                      loading="lazy"
                      style={{
                        scale: 0.8,
                        width: 200,
                        height: 200,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="p-5 section-recon mt-5 cover bg-white mb-3 position-relative ">
        <div className="position-absolute arrow-image d-none d-sm-none d-md-none d-lg-block">
          <img src={ArrowImg} alt="image" className="" />
        </div>
        <div
          className="row d-flex align-items-center justify-content-center justify-content-md-center justify-content-lg-between px-0 px-sm-0 px- 
         md-4 px-md-5 "
        >
          <div className="col-sm-12 col-md-10 col-lg-6">
            <h2
              className="pb-3 expense-mgt-title fs-1 text-left text-sm-left text-md-start text-lg-left"
              style={{
                fontWeight: 510,
              }}
            >
              {t("expense.sectionone.col.one.title")}
            </h2>
            <div className="lh-lg text-left text-sm-left text-md-left text-lg-left">
              {t("expense.sectionone.col.one.caption")}
            </div>
          </div>
          <div className="col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4 my-5 my-sm-5 my-md-5 my-lg-0 ">
            <div className="">
              <div
                className="text-center pb-4"
                style={{
                  color: "#707070",
                }}
              >
                {t("expense.sectionone.col.two.title")}
              </div>
              <div className="image-calc w-100 ">
                <img
                  src={NewPieChart}
                  width={"100%"}
                  className="w-100 h-100 img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section */}
      <div
        className=""
        style={{
          marginBottom: 70,
          marginTop: 70,
        }}
      >
        {/* expense title */}
        <div className="container-fluid overflow-hidden customize">
          <div className="row align-items-center justify-content-center ">
            <div className="col-11 col-sm-11 col-md-9 col-lg-6 col-xl-6 col-xxl-6 ">
              <div
                className="expense-title text-start text-md-start text-lg-start"
                // dangerouslySetInnerHTML={{
                //   __html: t("expense.sectiontwo.title"),
                // }}
                // dangerouslySetInnerHTML={{
                //   __html: t("expense.sectiontwo.title"),
                // }}
              >
                Customise expense policy to suit <br />
                <span className="grad-text">your team.</span>{" "}
              </div>
              <div
                className="expense-desc pt-3 lh-lg text-start "
                dangerouslySetInnerHTML={{
                  __html: t("expense.sectiontwo.caption"),
                }}
              >
                {/* Define a clear expense and approval flow policy for your team.
                Set spending <br /> limits to minimise fraud risks, and create
                customised reports that include all the <br /> details you want
                to monitor in real-time. */}
              </div>
            </div>
          </div>
        </div>

        {/* white background */}
        <div className="bg-white section-cover mt-5 overflow-hidden">
          <div className="container">
            {/* one */}
            <div className="row d-flex align-items-center justify-content-between py-5">
              <div
                className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-3 my-sm-0  my-md-0  my-lg-0 "
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="">
                  <h2 className="text-start expense-mgt-title text-md-start text-lg-start">
                    {t("expense.sectionthree.col.one.title")}
                  </h2>
                  <div className="text-start text-md-start text-lg-start px-2 px-sm-0 pr-md-3 pr-lg-3">
                    {t("expense.sectionthree.col.one.caption")}
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-5 col-xl-5 position-relative my-3 my-sm-0 my-md-0 my-lg-0 "
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div
                  className="d-flex align-items-center px-3  justify-content-start justify-content-sm-center my-3 my-sm-0  my-md-0  my-lg-0"
                  style={{
                    width: 370,
                    height: 370,
                  }}
                >
                  <div
                    className="position-absolute"
                    style={{
                      left: -60,
                      top: 50,
                    }}
                  >
                    <img src={Sparkles} className="" alt={"sparkles"} />
                  </div>
                  <div
                    className="d-flex pr-3 align-items-center justify-content-end expense-image__cover "
                    style={{
                      zIndex: 80,
                    }}
                  >
                    <img
                      src={NewApprovalImg}
                      alt={""}
                      width={"100%"}
                      height={"100%"}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* two */}
            <div className="row align-items-center justify-content-between py-5 flex-row-reverse ">
              <div
                className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-3 my-sm-0 my-md-0 my-lg-0"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="">
                  <h2 className="text-start expense-mgt-title text-md-start text-lg-start">
                    {t("expense.sectionthree.col.two.title")}
                  </h2>
                  <div className="text-start text-md-start text-lg-start px-2 px-sm-0 pr-md-3 pr-lg-3">
                    {t("expense.sectionthree.col.two.caption")}
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-5 col-xl-5 px-3 position-relative my-3 my-sm-0  my-md-0  my-lg-0 "
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div
                  style={{
                    width: 370,
                    height: 370,
                  }}
                >
                  <div
                    className="position-absolute"
                    style={{
                      right: 100,
                      top: 50,
                    }}
                  >
                    <img src={Sparkles} className="" alt={"sparkles"} />
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-end expense-image__cover "
                    style={{
                      zIndex: 80,
                    }}
                  >
                    <img
                      src={ExpenseImg2}
                      alt={""}
                      width={"100%"}
                      height={"100%"}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* three */}
            <div className="row align-items-center justify-content-between py-5 ">
              <div
                className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-3 my-sm-0 my-md-0 my-lg-0"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="">
                  <h2 className="text-start expense-mgt-title text-md-start text-lg-start">
                    {t("expense.sectionthree.col.three.title")}
                  </h2>
                  <div
                    className="text-start text-md-start text-lg-start px-2 px-sm-0 pr-md-3 pr-lg-3"
                    dangerouslySetInnerHTML={{
                      __html: t("expense.sectionthree.col.three.caption"),
                    }}
                  >
                    {/* {t("")} */}
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-6 col-xl-6 position-relative my-3 my-sm-0  my-md-0  my-lg-0 "
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div
                  style={{
                    width: 370,
                    height: 370,
                  }}
                >
                  <div
                    className="position-absolute d-none"
                    style={{
                      left: -80,
                      top: 50,
                    }}
                  >
                    <img src={Sparkles} className="" alt={"sparkles"} />
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-end expense-image__cover "
                    style={{}}
                  >
                    <img
                      src={ExpenseImg3}
                      alt={""}
                      width={"100%"}
                      height={"100%"}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TripsSection />
      </div>

      <Decisions />
      <Footer />
    </div>
  );
};

export default ExpenseManagement;
