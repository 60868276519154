export const frenchdata = {
  translation: {
    navBar: {
      headerOne: "Holla remis",
      headerTwo: "Holla Featured",
      headerThree: "Holla started",
      featureModal: {
        expense: {
          title: "frExpense management.",
          desc: "Manage your time spend with ease.",
        },
        card: {
          title: "frCorporate cards.",
          desc: "Create and manage cards on the go..",
        },
        trips: {
          title: "frTrips.",
          desc: "Improve your logistics experience",
        },
        fleet: {
          title: "frFleet management.",
          desc: "Get better control of your company’s vehicles",
        },
      },
    },
    // Landing Page,
    landing: {
      heroCaptionOne: "frStreamline your <br /> business expenses.",
      heroCaptionTwo: "frCreate budgets, and <br /> track your expenses.",
      caption: `frOrganise your business expenses across multiple locations,
        monitor daily expenses, gain insights into your business
        financial performance and eliminate inefficient manual
        processes`,
      cta: {
        btnOne: "frSchedule a demo",
        btnTwo: "frContact sales",
      },
      takeCharge: {
        title: "frTake charge of your business <br /> finances with Remis",
        col: {
          one: {
            title: "frCustomise Your Workflow.",
            desc: `Reduce the necessity for manual payment approval. Create a workflow tailored to your business needs and make your payments with 99.9% efficiency.`,
            tag: "work",
          },
          two: {
            title: "frClose Your Books Faster.",
            desc: `Automate routine tasks like invoicing, payments and reconciliation by seamlessly syncing your accounting software and spend more time growing 
    your business.`,
            tag: "improve",
          },
          three: {
            title: "frImprove visibility.",
            desc: `Magnify spend visibility, strengthen accountability and encourage cross-functional collaboration, empowering you and your team to make informed decisions..`,
            tag: "visible",
          },
        },
      },
      expenseMgt: {
        title: "frExpense Management",
        caption: `frManage Business Expenses,
          <br /> Anywhere`,
        desc: `frMonitor your business spending and cash flow from anywhere. Get
          your hands on your financial performance by managing your business
          expenses, and financial assets. Lose no time to bureaucracies and
          time-consuming delays. Empower your employees to raise
          requisitions and accelerate authorised disbursements with ease.`,
        btn: "frDiscover expense management.",
        helperText: "frSpend overview by category.",
      },
      cards: {
        title: "frCooperate cards.",
        caption: "frCreate and Issue Multipurpose Cards.",
        desc: `frCreate and fund trackable physical or virtual cards for different
          purposes for you and your team.`,
        bulletOne: "frCreate debit cards for out-of-station expenses",
        bulletTwo: "frAssign and track the cards created",
        bulletThree: "frControl card spending",
        btn: "frDiscover corporate cards",
      },
      fleet: {
        title: "frFleet management",
        caption: "frTake control of your fleet operations",
        desc: `frGet better control of your company’s vehicles. You can register
          your vehicles and drivers on Remis and assign them to an
          employee or driver. You can also manage your fleet fueling.`,
        btn: "frExplore fleet management",
        trips: {
          title: "frTrips",
          caption: "frMore control on official trips",
          desc: `frIncrease incentives for your employees by managing their
            official trips. Make the most of Remis to improve your logistics
            experience when team members are out and about on company
            assignments.`,
          btn: "frRead more on trips",
        },
      },
      business: {
        title: `Business payment solution for <br />
          <span className="grad-text">seamless</span> spending processes`,
        col: {
          one: {
            title: "",
            caption: "",
          },
        },
      },
      expense: {
        title: ` Track your expenses <br /> <span className="grad-text">anywhere</span>`,
        caption: `frMonitor spending and cash flow with our easy-to-use software
          solution. Focus on growing your business and let Remis take care of
          your business spending just the way you want it when you want it.`,
      },
      decision: {
        title: `frMake smart financial decisions <br /> with
          <span style={{ color: "#001755" }}>Remis</span>`,
        caption: "",
        desc: `frReconcile expenses, track spending, and monitor your company’s
          financial <br /> performance in real-time with our cutting-edge
          technology and intuitive <br /> spend management platform.`,
      },
    },
    // expense
    expense: {
      hero: {
        title: `frSimplify your company's accounting system; integrate it 
          with
            Remis.`,
        caption: `frEliminate the added pressure of managing multiple 
         accounting
            systems. Simply harmonise everything in one place.`,
      },
      sectionone: {
        col: {
          one: {
            title: "frReconcile your books effortlessly",
            caption: `frWith Remis you can synchronise your company’s 
             accounting system,
              confirm expenses, customise your Remis account to fit your
              company’s policies, and ensure accuracy on all transactions. 
               You
              can delegate access, conduct card reconciliation, reimburse 
               your
              team, and raise requisitions promptly.`,
          },
          two: {
            title: "frMoney spent this month",
          },
        },
      },
      sectiontwo: {
        title: `frCustomise expense policy to suit <br />
          <span className="grad-text" style="">your team.</span>`,
        caption: `frDefine a clear expense and approval flow policy for your team.
          Set spending <br /> limits to minimise fraud risks, and create
          customised reports that include all the <br /> details you want
          to monitor in real-time.`,
      },
      sectionthree: {
        col: {
          one: {
            title: "frMulti-level approvals",
            caption: `frStreamline the expense approval process with our multi-level approval functionality.
               With Remis, all expenses will be reviewed and approved by multiple parties, according to the workflow put
               in place, ensuring that all expenses are properly 
               authorized before they are paid.`,
          },
          two: {
            title: "frBudgeting",
            caption: `frRemis includes powerful budgeting tools that allow you to set and track your 
              organization's budget in real-time. You can create budgets for individuals, departments, branches, projects, 
              or any other category that makes sense for your organization`,
          },
          three: {
            title: "frRequisition & Reimbursement",
            caption: `frImprove efficiency in raising requisitions and pay back your team members for any out-of-pocket
               spending through your Remis account. Remis streamlines the process, making it easy for employees to request
                the funds they need to do their job.`,
          },
        },
      },
    },
    // Footer
    footer: {
      title: `frRemis is a Cloud Finance Management and Reporting platform for
        businesses and individuals. At Remis, we help businesses to
        control their expense by improving their accounting.`,
      company: {
        title: "frCompany",
        links: {
          one: "frAbout Us",
          two: "frPrivacy Policy",
          three: "frTerms and Conditions",
        },
      },
      product: {
        title: "frProducts",
        links: {
          one: "frCard",
          two: "frApp dashboard",
          three: "frWeb dashboard",
        },
      },
      learn: {
        title: "frLearn",
        links: {
          one: "frBlog",
          two: "frFAQ",
          three: "frTerms and Conditions",
        },
      },
      contact: {
        title: "frContact",
        links: {
          one: "frInfo@remis.africa",
          two: "frContact Us",
        },
      },
    },
    // Components
    component: {
      trips: {
        row: {
          one: {
            title: "frTrips",
            caption: `frIncrease organisational incentives for your <br />
                employees by managing and paying for their work-related
                trips.`,
          },
          two: {
            title: "frExpense Management",
            caption: `frCreate and fund trackable physical or virtual <br />
                cards catered specifically to organisational unique needs.`,
          },
          three: {
            title: "frFleet Management",
            caption: `frMake the most of Remis to manage your fleet <br /> fueling
                by creating vouchers and customised cards.`,
          },
        },
      },
    },
    // cards
    cards: {
      pageTitle: "frCards",
      hero: {
        title: "frSimplify payment with a Remis virtual or physical card.",
        caption: "Give your team access to funds when they need it.",
        btn: "frGet started",
      },
      sectionone: {
        title: `frCreate <span className="grad-text">smooth payment</span> 
         <br />
        processes`,
        caption: `frEliminate the hassles of manual payment with utility, 
         fuel, and
        debit cards. Keep your team within budget and promote safe
        spending through our expense cards which are available
        physically and virtually. Likewise, you can eliminate
        out-of-pocket spending, freeze cards, ensure real-time
        compliance and expedite payments seamlessly with the
        multipurpose cards that Remis offers.`,
      },
      sectiontwo: {
        row: {
          one: {
            title: "frPhysical Debit Card.",
            caption: "",
            list: {},
          },
          two: {
            title: "frVirtual Debit Card.",
            caption: "",
            list: {},
          },
          three: {
            title: "frUtility Card.",
            caption: "",
            list: {},
          },
        },
      },
      sectionThree: {
        title: ` Save time and money with Remis
          <br /> <span className="grad-text">expense</span> card`,
        rowone: {
          col: {
            one: {
              title: "frMake fast payments",
              caption: `frWith our multipurpose card system, you can create 
               independent
                  cards for utility payments, fuelling and debits whether 
                   you are
                  making one-off purchases or a recurring subscription.`,
            },
            two: {
              title: "frSet limits",
              caption: `frSet limits on how much your team can spend by 
               issuing them
                  expense cards with a budget cap, and if you'd like to 
                   reduce or
                  increase the limit, Remis allows that too.`,
            },
            three: {
              title: "frAssign cards",
              caption: `frAssign Remis cards to selected team members and 
               fast-track
                  payments. That’s not all, you and your accounting team can
                  monitor what the cards are being spent on per time..`,
            },
          },
        },
        rowtwo: {
          col: {
            one: {
              title: "frTrack cards",
              caption: `frTrack, change, freeze, and issue new cards to 
               members of your
                  team. You can also generate a comprehensive report of 
                   spending
                  activities on your Remis expense card.`,
            },
            two: {
              title: "frDynamic control",
              caption: `frSet limits and restrictions on how and where the 
               cards can be
                  used, assign cards to employees, set geo-restrictions and 
                   alerts
                  for when card usage approaches or exceeds limits.`,
            },
            three: {
              title: "frReal time report",
              caption: `Get detailed reporting and analytics, so you can see 
               exactly
                  where your money is going and identify areas where you can 
                   save.`,
            },
          },
        },
      },
    },
    // Fleet
    fleet: {
      title: `frSimplify your company’s accounting system; integrate it with Remis.`,
      caption: `frEliminate the added pressure of managing multiple accounting systems. Simply harmonise everything in one place.`,
      monitor: {
        title: `frMonitor and control your team 
        <span className="grad-text">trips</span> `,
        caption: ` frEliminate the hassles of manual payment with utility, fuel,
        and debit cards. Keep your team within budget and promote safe
        spending through our expense cards`,
      },
      sectionone: {
        col: {
          one: {
            title: `fr Who did we build fleet management for?`,
            caption: `fr Eliminate the hassles of manual payment with utility, fuel,
            and.`,
          },
          two: {
            title: `frMonitor and control your team`,
            caption: ` fr Eliminate the hassles of manual payment with utility, fuel,
            and debit cards. Keep.`,
          },
          three: {
            title: "frVouchers",
            caption: `frReduce the necessity for manual payment approval.
            Create a workflow tailored to your business needs
            and make your payments with`,
            text: `fr 99.9% efficiency.`,
          },
          four: {
            title: "frFleet card",
            caption: ` fr Reduce the necessity for manual payment approval. Create a
            workflow tailored to your business needs and make your
            payments with 99.9% efficiency.`,
          },
          five: {
            title: ` frWhy you should use remis
             fleet management`,
            caption: `   Eliminate the hassles of manual payment with utility, fuel, and
            debit cards. Keep.`,
          },
          six: {
            title: ` frManage and control your drivers`,
            caption: `    fr Increase incentives for your employees by managing their
            official trips. Make the most of Remis to improve your
            logistics experience when team members are out and about on
            company assignments. .`,
          },
          seven: {
            title: ` frRead more on trips`,
            caption: ` `,
          },
          eight: {
            title: `frVouchers`,
            caption: ` frReduce the necessity for manual payment approval.
            Create a workflow tailored to your business needs and
            make your payments with 99.9% efficiency.
            `,
          },
          nine: {
            title: ` fr Real time report`,
            caption: `frReduce the necessity for manual payment approval.
            Create a workflow tailored to your business needs and
            make your payments with 99.9% efficiency. `,
          },
        },
      },
    },
    // community
    community: {
      title: `frCommunity benefits`,
      priority: `frBecome a priority everywhere : </b>Get treated as a VIP at
      every spot you book a reservation for on Prime X`,
      explore: `frExplore luxury based alternatives :</b> Find luxury based
      activities around you and within your budget`,
      maximum: `frMaximium value for your money: </b>
      Enjoy distinct services that matches and surpasses every kobo
      spent`,
      secure: `frSecure & hassle-free payments: </b>
      pay for every service even before demand with your Prime X
      wallet`,
      connect: ` frConnect with other HNIs : </b> Meet new HNIs in the
      community and when one is around you offline`,
    },
    contact: {
      title: ``,
      caption: `fr Want to know more about how to open a Remis account for your
      team? Kindly fill the form . You can also give us a call on
      insert number and we’ll be in touch.`,
      button: `frSend a message`,
      row: {
        one: `frFull name`,
        two: `frEmail`,
        three: `frPhone number`,
        four: `frLeave a note`,
      },
      placeholder: {
        firstname: `frFirst Name`,
        lastname: `frLast Name`,
        email: `frmail@erastus.com`,
        phone: `fr+234-XXX--XXX-XX`,
        note: `frMin.8 password`,
      },
    },
    // Trips
    trips: {
      title: `   frStreamline Your Commute Expenses with Remis Trips`,
      caption: ` frGive your team access to funds when they need it.`,
      sectionOne: {
        title: ``,
        caption: ` frRequest and approve commutes directly on the Remis app, gain
    better control of your company’s expenses, identify spending
    patterns and make informed decisions.`,
      },
      sectionTwo: {
        title: `frRequest Payment for Trips`,
        caption: `frEliminate out-of-pocket spending and reimbursements when
    you go out on official assignments. Easily request payment
    for your commutes and have your expenses taken care of by
    your employer.`,
      },
      sectionThree: {
        title: `frReview and Approve Trip Request`,
        caption: `frEfficiently review and approve payment for your employee's
    official commutes, generate vouchers on ride-hailing
    service providers, and track employee commute expenses,
    ensuring that your company is only paying for necessary
    expenses.`,
      },
      sectionFour: {
        title: `frConsumption update`,
        caption: ` frTake control over your employee commute expense by
    receiving updates on trip requests and voucher
    consumption. With this, you can have a better
    understanding of employee commute patterns and be able to
    better plan and budget for the future.`,
      },
      sectionFive: {
        title: `frGenerate report`,
        caption: ` frStay on top of trip expenses with detailed reporting and
    analytics. Generate reports on all trips requested and
    approved, vouchers generated and consumed, and ensure you
    are staying within the budget.`,
      },
    },
  },
};
