import Chart from "chart.js/auto";
import { Doughnut, Pie } from "react-chartjs-2";

const DoughnutChart = () => {
  return (
    <Doughnut
      data={{
        labels: ["UI/UX", "Frontend", "Fields", "Others"],
        datasets: [
          {
            type: "doughnut",
            label: "",
            data: [60, 10, 20, 20],
            backgroundColor: ["#099268", "#ffc68f", "#aa99df", "#7986a6"],
            hoverOffset: 4,
            borderRadius: 10,
          },
        ],
      }}
      options={{
        animation: {},
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        elements: {},
        maintainAspectRatio: false,
        responsive: true,
        cutout: 80,
      }}
    />
  );
};

export default DoughnutChart;
