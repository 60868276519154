import PhoneMock from "../../assets/images/[Mockup] iPhone 14-1.png";

import "./index.css";
import RemisDualCards from "../../assets/images/remisDualCards.png";
import Frame1 from "../../assets/images/frame1.png";
import Frame4 from "../../assets/images/frame4.png";
import Lines from "../../assets/images/Vector.png";
import { useTranslation } from "react-i18next";
function ExpenseTracking() {
  const { t, i18n } = useTranslation();
  return (
    <section className="take-charge-part d-none  d-sm-none d-md-none d-lg-block mt-5 pt-3">
      <div
        className="container-fluid  position-relative "
        style={{}}
      >
        {/* lines */}
        <div
          className="position-absolute d-none d-sm-none d-md-none d-lg-block"
          style={{
            left: 10,
            top: "-9%",
           
          }}
        >
          <img
            src={Lines}
            alt={"lines"}
            className="img-fluid w-100 h-100"
            loading="lazy"
          />
        </div>
      <div className="rm-container">
        <h2
          className="lead-body-text"
          // dangerouslySetInnerHTML={{ __html: t("landing.expense.title") }}
        >
          Track your expenses <br /> <span className="grad-text">anywhere</span>
        </h2>
        <div className="heading-body-p d-flex justify-content-center pb-4 mx-auto">
          <div className="text-caption pt-2 plus-jarkata">
            {t("landing.expense.caption")}
          </div>
        </div>
      </div>
      <br />
      <div
        className="justify-content-between d-none d-sm-none d-md-flex d-lg-flex "
        // style={{ marginTop: "-28rem" }}
      >
        <div
          className="w-100 mr-5"
          data-aos="fade-down"
          data-aos-duration="800"
          style={{
            zIndex:100
          }}
        >
          <img src={Frame1} width="100%" />
        </div>
        <div
          className=" w-100 placement-phase-1 mr-5"
          data-aos="fade-up"
          data-aos-duration="800"
          style={{ marginTop: "5rem",   zIndex:100 }}
        >
          <img src={PhoneMock} width="100%" />
        </div>
        <div
          className="w-100 placement-phase-2 h-75 mr-5"
          data-aos="fade-down"
          data-aos-duration="800"
          style={{ marginTop: "20rem" }}
        >
          <img src={RemisDualCards} width="100%" />
        </div>
        <div
          className=" w-100 placement-phase-3"
          data-aos="fade-up"
          data-aos-duration="800"
          style={{ marginTop: "40rem" }}
        >
          <img src={Frame4} width="100%" />
        </div>
      </div>
      </div>

    </section>
  );
}

export default ExpenseTracking;
