import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { englishdata } from "./Languages/english";
import { frenchdata } from "./Languages/french";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: englishdata,
    fr: frenchdata,
  },
});

export default i18n;
