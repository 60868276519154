import React from "react";
import Lines from "../../assets/images/Vector.png";
import LadyLaptop from "../../assets/images/ladyLaptopmain.png";

const about = () => {
  return (
    <div>
      <div className="">
        <div className="header-hero overflow-hidden pb-4 ">
          <div className="position-relative ">
            <div className="container-fluid header-container  " style={{}}>
              {
                <div
                  className="position-absolute card__ animate__animated animate__fadeInUp d-none d-sm-none d-md-none d-lg-block"
                  style={{
                    left: 10,
                    bottom: 0,
                  }}
                >
                  <img
                    src={Lines}
                    alt={"lines"}
                    className="img-fluid w-100 h-100"
                    loading="lazy"
                  />
                </div>
              }

              <div
                className="position-absolute left-body animate__animated animate__fadeInRight d-none d-sm-none d-md-none d-lg-block"
                style={{
                  right: 0,
                  bottom: -30,
                  width: 800,
                }}
              >
                <img src={LadyLaptop} alt="" style={{
                  width:"900px",
                  // height:"600px",
                }} />
              </div>

              <div
                className="row header-row mt-lg-3 d-flex align-items-start justify-content-between center justify-content-sm-between justify-content-md- 
             center justify-content-lg-between px-sm-4 "
                style={{}}
              >
                <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 animate__animated animate__fadeInLeft">
                  <div className="text-sm-left text-md-center text-lg-left">
                    <div>
                      {" "}
                      <h2
                        className="grad-text text-start lead-body-text  mt-5 mt-sm-5 mt-md-5"
                        style={{
                          textAlign: "start",
                          paddingTop: 50,
                        }}
                      >
                        About Us
                      </h2>
                    </div>

                    <div className=" lead-light-text text-left text-sm-center text-md-center text-lg-left mt-0 mt-sm-0 mt-md-0 mt-lg-2">
                      The company was born out of a need to fill the gap in the
                      provision of <br /> top-notch automation and software
                      solution, particularly in the downstream <br /> oil and
                      gas sector. Our aim is to provide end to end solutions to
                      the challenges <br /> of downstream sector of the oil and
                      gas industry across Africa.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
