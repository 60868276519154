import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ExpenseFImg,
  VcardImg,
  NavigationImg,
  FeaturesCar,
} from "../../../assets/svgs/";
import "./style.css";
import { useTranslation } from "react-i18next";

const FeaturesDd = ({ setAccess }) => {
  const { i18n, t } = useTranslation();
  return (
    <div
      className="position-absolute features-dd_cover animate__animated animate__fadeIn"
      style={{
        width: 700,
      }}
    >
      <div
        className="position-relative bg-white shadow-sm"
        style={{
          top: -80,
          border: "1px solid #EAF0FF",
          borderRadius: 10,
        }}
      >
        <div className="container-fluid position-relative ">
          <div className="row px-3">
            <div className="col-6 my-3">
              <Link
                to="/expense"
                className="d-flex align-items-center pointer text-decoration-none"
              >
                <div className="features-dd_icon expense d-flex align-items-center justify-content-center">
                  <ExpenseFImg />
                </div>
                <div className="ml-3">
                  <div className="features-dd_title text-decoration-none pointer text-dark">
                    {t("navBar.featureModal.expense.title")}
                  </div>
                  <div className="feature-dd_caption text-decoration-none pointer">
                    {t("navBar.featureModal.expense.desc")}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 my-3">
              <Link
                to="/cards"
                className="d-flex align-items-center pointer text-decoration-none"
              >
                <div className="features-dd_icon cards d-flex align-items-center justify-content-center">
                  <VcardImg />
                </div>
                <div className="ml-3">
                  <div className="features-dd_title text-decoration-none pointer text-dark">
                    {t("navBar.featureModal.card.title")}
                  </div>
                  <div className="feature-dd_caption text-decoration-none pointer ">
                    {t("navBar.featureModal.card.desc")}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 my-3">
              <Link
                to="/trips"
                className="d-flex align-items-center pointer text-decoration-none"
              >
                <div className="features-dd_icon trips d-flex align-items-center justify-content-center">
                  <NavigationImg />
                </div>
                <div className="ml-3">
                  <div className="features-dd_title text-decoration-none pointer text-dark">
                    {t("navBar.featureModal.trips.title")}
                  </div>
                  <div className="feature-dd_caption text-decoration-none pointer">
                    {t("navBar.featureModal.trips.desc")}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 my-3">
              <Link
                to="/fleet"
                className="d-flex align-items-center pointer d-none text-docaration-none"
              >
                <div className="features-dd_icon fleet d-flex align-items-center justify-content-center">
                  <FeaturesCar />
                </div>
                <div className="ml-3">
                  <div className="features-dd_title text-decoration-none pointer text-dark">
                    {t("navBar.featureModal.fleet.title")}
                  </div>
                  <div className="feature-dd_caption text-decoration-none pointer">
                    {t("navBar.featureModal.fleet.desc")}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesDd;


