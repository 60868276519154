import React from "react";
import Young from "../../assets/images/young.png";

const ourMission = () => {
  return (
    <div className="d-none d-sm-none d-md-none d-lg-block">
      <div className="  vision-container  d-flex align-items-center justify-content-center  ">
        <div className="">
          <div className="position-relative ">
            <div className="">
            <div className="mission-top-cover"></div>
            <div
              className="mission-cover  position-absolute "
              style={{
                bottom: "-40%",
                right: "-3%",
                zIndex: 10,
              }}
            >
              <h2 className="">Our Vision</h2>
              <p>
                Our solutions are tailored to meet specific needs of the oil and
                gas industry, providing solutions to the biggest without
                sacrificing flexibility to accommodate smaller clients. We go
                the extra miles to bring satisfaction to our clients.
              </p>
            </div>
            </div>
            <div className="">
              <img
                className="mission-img"
                src={Young}
                alt=""
                style={{
                  width: "1119px",
                  height: "400px",
                }}
              />
            </div>
            <div className="">
              <div className="mission_top_cover"></div>
              <div
                className="mission__cover position-absolute "
                style={{
                  top: "-30%",
                  left: "0%",
                  zIndex: 10,
                }}
              >
                <h2 className="">Our Mission</h2>
                <p>
                  Our solutions are tailored to meet specific needs of the oil
                  and gas industry, providing solutions to the biggest without
                  sacrificing flexibility to accommodate smaller clients. We go
                  the extra miles to bring satisfaction to our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ourMission;
