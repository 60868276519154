export const englishdata = {
  translation: {
    // Navbar
    navBar: {
      headerOne: "Home",
      headerTwo: "Features",
      headerThree: "Get started",
      featureModal: {
        expense: {
          title: "Expense management.",
          desc: "Manage your time spend with ease.",
        },
        card: {
          title: "Corporate cards.",
          desc: "Create and manage cards on the go..",
        },
        trips: {
          title: "Trips.",
          desc: "Improve your logistics experience",
        },
        fleet: {
          title: "Fleet management.",
          desc: "Get better control of your company’s vehicles",
        },
      },
    },
    // Landing Page,
    landing: {
      heroCaptionOne: ` Streamline your `,
      heroCaptionTwo: "business expense.",
      heroCaptionThree: "business workflow.",
      heroCaptionFour: "business budget.",
      heroCaptionFive: "business reports.",
      heroCaptionSix: "business book-keeping.",
      caption: `Organise your business expenses across multiple locations,
        monitor daily <br/> expenses, gain insights into your business
        financial performance and <br/> eliminate inefficient manual
        processes`,
      cta: {
        btnOne: "Schedule a demo",
        btnTwo: "Contact sales",
      },
      takeCharge: {
        title: "Take charge of your business <br /> finances with Remis",
        col: {
          one: {
            title: "Customise Your Workflow.",
            desc: `Reduce the necessity for manual payment approval. Create a workflow tailored to your business needs and make your payments with 
             99.9% efficiency.`,
            tag: "work",
          },
          two: {
            title: "Close Your Books Faster.",
            desc: `Automate routine tasks like invoicing, payments and reconciliation
           by seamlessly syncing your accounting software and spend more time growing 
            your business.`,
            tag: "improve",
          },
          three: {
            title: "Improve visibility.",
            desc: `Magnify spend visibility, strengthen accountability and encourage 
                 cross-functional collaboration, empowering you and your team to make informed 
           decisions..`,
            tag: "visible",
          },
        },
      },
      expenseMgt: {
        title: "Expense Management",
        caption: `Manage Business Expenses,
          <br /> Anywhere`,
        desc: `Monitor your business spending and cash flow from anywhere. Get
          your hands on your financial performance by managing your business
          expenses, and financial assets. Lose no time to bureaucracies and
          time-consuming delays. Empower your employees to raise
          requisitions and accelerate authorised disbursements with ease..`,
        btn: "Discover expense management.",
        helperText: "Spend overview by category.",
      },
      cards: {
        title: "Cooperate cards.",
        caption: "Create and Issue Multipurpose Cards.",
        desc: `Create and fund trackable physical or virtual cards for different
          purposes for you and your team.`,
        bulletOne: "Create debit cards for out-of-station expenses",
        bulletTwo: "Assign and track the cards created",
        bulletThree: "Control card spending",
        btn: "Discover corporate cards",
      },
      fleet: {
        title: "Fleet management",
        caption: "Take control of your fleet operations",
        desc: `Get better control of your company’s vehicles. You can register
          your vehicles and drivers on Remis and assign them to an
          employee or driver. You can also manage your fleet fueling.`,
        btn: "Explore fleet management",
        trips: {
          title: "Trips",
          caption: "More control on official trips",
          desc: `Increase incentives for your employees by managing their
            official trips. Make the most of Remis to improve your logistics
            experience when team members are out and about on company
            assignments.`,
          btn: "Read more on trips",
        },
      },
      business: {
        title: `Business payment solution for <br />
          <span className="grad-text">seamless</span> spending processes`,
        col: {
          one: {
            title: "",
            caption: "",
          },
        },
      },
      expense: {
        title: `Track your expenses <br /> <span className="grad-text">anywhere</span>`,
        caption: `Monitor spending and cash flow with our easy-to-use software
          solution. Focus on growing your business and let Remis take care of
          your business spending just the way you want it when you want it.`,
      },
      decision: {
        title: `Make smart financial decisions <br /> with
          <span style={{ color: "#001755" }}>Remis</span>`,
        caption: "",
        desc: `Reconcile expenses, track spending, and monitor your company’s
          financial <br /> performance in real-time with our cutting-edge
          technology and intuitive <br /> spend management platform.`,
      },
    },
    // footer
    footer: {
      title: `Remis is a Cloud Finance Management and Reporting platform for
        businesses and individuals. At Remis, we help businesses to
        control their expense by improving their accounting.`,
      company: {
        title: "Company",
        links: {
          one: "About Us",
          two: "Privacy Policy",
          three: "Terms and Conditions",
        },
      },
      product: {
        title: "Features",
        links: {
          one: "Expense Management",
          two: "Corporate cards",
          three: "Trips",
          four: "Fleet Management",
        },
      },
      learn: {
        title: "Learn",
        links: {
          one: "Blog",
          two: "FAQ",
          three: " ",
        },
      },
      contact: {
        title: "Contact",
        links: {
          one: "Info@remis.africa",
          two: "Contact Us",
          three: " Terms and Conditions",
        },
      },
    },
    // Expense
    expense: {
      hero: {
        title: `Simplify your company's accounting system; integrate it with Remis.`,
        caption: `Eliminate the added pressure of managing multiple accounting
        systems. Simply harmonise everything in one place.`,
      },
      sectionone: {
        col: {
          one: {
            title: "Reconcile your books effortlessly",
            caption: `With Remis you can synchronise your company’s accounting system,
              confirm expenses, customise your Remis account to fit your
              company’s policies, and ensure accuracy on all transactions. You
              can delegate access, conduct card reconciliation, reimburse your
              team, and raise requisitions promptly.`,
          },
          two: {
            title: "Money spent this month",
          },
        },
      },
      sectiontwo: {
        title: `Customise expense policy to suit <br />
          <span className="grad-text">your team.</span>`,
        caption: `Define a clear expense and approval flow policy for your team.
          Set spending <br /> limits to minimise fraud risks, and create
          customised reports that include all the <br /> details you want
          to monitor in real-time.`,
      },
      sectionthree: {
        col: {
          one: {
            title: "Multi-level approvals",
            caption: `Streamline the expense approval process with our multi-level approval functionality.
               With Remis, all expenses will be reviewed and approved by multiple parties, according to the workflow put
               in place, ensuring that all expenses are properly 
               authorized before they are paid.`,
          },
          two: {
            title: " Budgeting",
            caption: `Remis includes powerful budgeting tools that allow you to set and track your 
              organization's budget in real-time. You can create budgets for individuals, departments, branches, projects, 
              or any other category that makes sense for your organization`,
          },
          three: {
            title: "Requisition & Reimbursement",
            caption: `Improve efficiency in raising requisitions and pay back your team <br />
             members for any out-of-pocket
               spending through your Remis  <br /> account. Remis streamlines the process, making it easy for  <br /> employees to request
                the funds they need to do their job.`,
          },
        },
      },
    },
    // Components
    component: {
      trips: {
        row: {
          one: {
            title: "Trips",
            caption: `Increase organisational incentives for your <br />
              employees by managing and paying for their work-related
              trips.`,
          },
          two: {
            title: " Expense Management",
            caption: ` Create and fund trackable physical or virtual <br />
              cards catered specifically to organisational unique needs.`,
          },
          three: {
            title: " Fleet Management",
            caption: `Make the most of Remis to manage your fleet <br /> fueling
              by creating vouchers and customised cards.`,
          },
        },
      },
    },
    // cards
    cards: {
      pageTitle: "Cards",
      hero: {
        title: "Simplify payment with a Remis virtual or physical card.",
        caption: "Give your team access to funds when they need it.",
        btn: "Get started",
      },
      sectionone: {
        title: `Create <span className="grad-text">smooth payment</span> <br />
        processes`,
        caption: `Eliminate the hassles of manual payment with utility, fuel, and
        debit cards. Keep your team within budget and promote safe
        spending through our expense cards which are available
        physically and virtually. Likewise, you can eliminate
        out-of-pocket spending, freeze cards, ensure real-time
        compliance and expedite payments seamlessly with the
        multipurpose cards that Remis offers.`,
      },
      sectiontwo: {
        row: {
          one: {
            title: "Physical Debit Card.",
            caption: "",
            list: {},
          },
          two: {
            title: "Virtual Debit Card.",
            caption: "",
            list: {},
          },
          three: {
            title: "Utility Card.",
            caption: "",
            list: {},
          },
        },
      },
      sectionThree: {
        title: ` Save time and money with Remis
          <br /> <span className="grad-text">expense</span> card`,
        rowone: {
          col: {
            one: {
              title: "Make fast payments",
              caption: ` With our multipurpose card system, you can create independent
              cards for utility payments, fuelling and debits whether you are
              making one-off purchases or a recurring subscription.`,
            },
            two: {
              title: "Set limits",
              caption: `Set limits on how much your team can spend by issuing them
              expense cards with a budget cap, and if you'd like to reduce or
              increase the limit, Remis allows that too.`,
            },
            three: {
              title: "Assign cards",
              caption: `Assign Remis cards to selected team members and fast-track
              payments. That’s not all, you and your accounting team can
              monitor what the cards are being spent on per time..`,
            },
          },
        },
        rowtwo: {
          col: {
            one: {
              title: "Track cards",
              caption: `Track, change, freeze, and issue new cards to members of your
              team. You can also generate a comprehensive report of spending
              activities on your Remis expense card.`,
            },
            two: {
              title: "Dynamic control",
              caption: `Set limits and restrictions on how and where the cards can be
              used, assign cards to employees, set geo-restrictions and alerts
              for when card usage approaches or exceeds limits.`,
            },
            three: {
              title: "Real time report",
              caption: `Get detailed reporting and analytics, so you can see exactly
              where your money is going and identify areas where you can save.`,
            },
          },
        },
      },
    },
    fleet: {
      title: `Streamline Your Fueling Payment Process`,
      caption: `Effortlessly Manage Your Fleet and save money with our versatile payment options`,
      monitor: {
        title: `Multiple and trackable payment options for fueling `,
        caption: `Take control of your fleet expenses with our multiple and trackable payment options for fueling. Pay for your fuelling via vouchers, fuel cards or even USSD.`,
      },
      sectionone: {
        col: {
          one: {
            title: ` How can this help your business?`,
            caption: ` Anyone in the following category can take advantage of Remis Fleet Management`,
          },
          two: {
            title: `Monitor and track fleet expenses in real-time
            `,
            caption: `  Avoid high fuelling costs and low efficiency by tracking fuelling activities with our advanced reporting and analytics system.`,
          },
          three: {
            title: "Vouchers...",
            caption: `Quickly generate vouchers to pay for your fuelling on the Remis app. Remis vouchers provide a secure and efficient way of paying for fuel without carrying cash or a physical card, eliminating the need for manual payment processes that can be time-consuming and prone to errors.`,
          },
          four: {
            title: "Fleet card",
            caption: `  Conveniently create, assign and make fuel purchases with Remis fuel cards. With our fuel cards, you can track fuel purchases, monitor usage, and gain greater control over your fleet's spending.`,
          },
          five: {
            title: ` Why do you need Remis Fleet Management Service?`,
            caption: `  To remove the guesswork with your fleet expenses and saddle up more control for savings and efficiency `,
          },
          six: {
            title: ` Order Fuel`,
            caption: `   With our partnership with fuel stations around the country, you can now see which of these stations is closest to you and order fuel directly to your location, with your phone.`,
          },
          seven: {
            title: ` Read more on trips`,
            caption: ` `,
          },
          eight: {
            title: `Inventory Management`,
            caption: ` Register your vehicles on Remis and match them to a driver, thereby ensuring that each driver is only able to access the vehicles they are authorized to drive.
            `,
          },
          nine: {
            title: `  Cost Saving`,
            caption: `With access to our accurate and real-time data, admins can set limits and restrictions on fuel purchases and monitor them in real-time. This way, you ensure that drivers are using the fleet responsibly and efficiently eliminate unnecessary costs `,
          },
        },
      },
    },
    // Community
    community: {
      title: `Community benefits`,
      priority: `Become a priority everywhere : </b>Get treated as a VIP at
      every spot you book a reservation for on Prime X`,
      explore: `Explore luxury based alternatives :</b> Find luxury based
      activities around you and within your budget`,
      maximum: `Maximium value for your money: </b>
      Enjoy distinct services that matches and surpasses every kobo
      spent`,
      secure: `Secure & hassle-free payments: </b>
      pay for every service even before demand with your Prime X
      wallet`,
      connect: ` Connect with other HNIs : </b> Meet new HNIs in the
      community and when one is around you offline`,
    },
    // contact
    contact: {
      title: `Do you have further <br />
                questionsfor us?`,
      caption: `        Want to know more about how to open a Remis account for your
               team? Kindly fill the form . You can also give us a call on
              insert number and we’ll be in touch.`,
      button: `Send a message`,
      row: {
        one: `Full name`,
        two: `Email`,
        three: `Phone number`,
        four: `Leave a note`,
      },
      placeholder: {
        firstname: `First Name`,
        lastname: `Last Name`,
        email: `mail@erastus.com`,
        phone: `+234-XXX--XXX-XX`,
        note: `Tell us about your company and what you would like to know`,
      },
    },
    // FAQ
    faq: {
      title: ` Frequently Asked Questions`,
    },
    // Trips
    trips: {
      title: `   Streamline Your Commute Expenses with Remis Trips`,
      caption: ` Easily manage the process of paying for your employees' commutes with our Remis Trips feature`,
      sectionOne: {
        title: ``,
        caption: ` Request and approve commutes directly on the Remis app, gain
    better control of your company’s expenses, identify spending
    patterns and make informed decisions.`,
      },
      sectionTwo: {
        title: `Request Payment for Trips`,
        caption: `  Eliminate out-of-pocket spending and reimbursements when
    you go out on official assignments. Easily request payment
    for your commutes and have your expenses taken care of by
    your employer.`,
      },
      sectionThree: {
        title: `  Review and Approve Trip Request`,
        caption: `Efficiently review and approve payment for your employee's
    official commutes, generate vouchers on ride-hailing
    service providers, and track employee commute expenses,
    ensuring that your company is only paying for necessary
    expenses.`,
      },
      sectionFour: {
        title: `Consumption update`,
        caption: ` Take control over your employee commute expense by
    receiving updates on trip requests and voucher
    consumption. With this, you can have a better
    understanding of employee commute patterns and be able to
    better plan and budget for the future.`,
      },
      sectionFive: {
        title: `Generate report`,
        caption: ` Stay on top of trip expenses with detailed reporting and
    analytics. Generate reports on all trips requested and
    approved, vouchers generated and consumed, and ensure you
    are staying within the budget.`,
      },
    },
  },
};
