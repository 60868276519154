export const data = [
  {
    id: 1,
    title: "Funds transfer.",
    desc: `Make easy and free transfers on your Remis account to other Remis users. You can also transfer funds from your Remis to your bank account.    .`,
    tag: "funds",
  },
  {
    id: 2,
    title: "Bill Payments.",
    desc: `Save your team the time and stress of logging onto multiple websites or going from office to office. Organize your bills by signing up on Remis today..`,
    tag: "bill",
  },
  {
    id: 3,
    title: "Fueling.",
    desc: `Fuel purchase is made easier here as you can pay for fuel with trackable fuel vouchers or cards. These vouchers and cards cannot be exchanged for cash or, transferred to someone else..`,
    tag: "nuzzle",
  },
];

export const chargeData = [
  {
    id: 1,
    title: "Customise Your Workflow.",
    desc: `Reduce the necessity for manual payment approval. Create a workflow tailored to your business needs and make your payments with 99.9% efficiency.`,
    tag: "work",
  },
  {
    id: 2,
    title: "Close Your Books Faster.",
    desc: `Automate routine tasks like invoicing, payments and reconciliation
      by seamlessly syncing your accounting software and spend more time growing 
      your business.`,
    tag: "improve",
  },
  {
    id: 3,
    title: "Improve visibility.",
    desc: `Magnify spend visibility, strengthen accountability and encourage 
    cross-functional collaboration, empowering you and your team to make informed 
    decisions..`,
    tag: "visible",
  },
];
