import React from 'react'
import YellowLogo from "../../assets/svgs/yellowlogo.svg";
import PriceValue from "../../assets/images/pricevalue.png";

const coreValues = () => {
  return (
    <div>
<div className="">
          <div>
            <div className="bg-white ourstory-section"  >
              <div className="row  position-relative ">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 position-relative my-3 my-sm-0  my-md-0  my-lg-0 "data-aos="fade-up"
             data-aos-duration="2000" >
                  <div className="d-flex justify-content-center ">
                    <div className=" pt-4">
                      <h2
                        className=""
                        style={{
                          fontFamily: "Quiet Sans",
                          fontSize: " 48px",
                          fontWeight: 600,
                          lineHeight: " 62px",
                          letterSpacing: "-2px",
                          textAlign: "justify",
                        }}
                      >
                        Our Core Value
                      </h2>
                      <p className="ourstory-text">
                        Aliquam ac libero enim. Donec ipsum justo, finibus nec
                        aliquet <br /> pulvinar, sollicitudin sed lorem. Proin
                        porta hendrerit sem.
                        <br /> Aenean lacinia, purus id scelerisque vestibulum,
                        nisi sapien <br /> posuere lectus, in mattis dui lectus
                        ac ante. Mauris mattis <br /> neque imperdiet nisl
                        posuere, non tristique purus pulvinar
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 hide-mobile"></div>

                <div className="col-sm-12  col-md-6 col-lg-6 col-xl-6 position-relative my-3 my-sm-0  my-md-0  my-lg-0  ">
                  <div className="d-flex justify-content-center  ">
                    <div className="">
                      <img className="" src={PriceValue} />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center" data-aos="fade-down"
             data-aos-duration="2000">
                  <div className="col-md-6 col-12 hide-mobile mr-4">
                    <div className=" pt-4 ">
                      <div className="d-flex align-items-center ">
                        <div className="mr-2">
                          <img src={YellowLogo} alt="" />
                        </div>
                        <h5
                          className=""
                          style={{
                            fontFamily: "Quiet Sans",
                            fontSize: " 20px",
                            fontWeight: 600,
                            lineHeight: " 62px",
                            letterSpacing: "-2px",
                            textAlign: "justify",
                          }}
                        >
                          Customer Focus
                        </h5>
                      </div>

                      <p className="ourstory-text">
                        Our focus is building outstanding <br /> customer
                        experience and lasting <br /> relationship.
                      </p>
                    </div>
                    <div className=" pt-4">
                      <div className="d-flex align-items-center  ">
                        <div className="mr-2">
                          <img src={YellowLogo} alt="" />
                        </div>
                        <h5
                          className=""
                          style={{
                            fontFamily: "Quiet Sans",
                            fontSize: " 20px",
                            fontWeight: 600,
                            lineHeight: " 62px",
                            letterSpacing: "-2px",
                            textAlign: "justify",
                          }}
                        >
                          Customer Focus
                        </h5>
                      </div>

                      <p className="ourstory-text">
                        Our focus is building outstanding <br /> customer
                        experience and lasting <br /> relationship.
                      </p>
                      
                    </div>
                  </div>
                  <div className="col-md-6 col-12 hide-mobile">
                    <div className=" pt-4 ">
                      <div className="d-flex align-items-center ">
                        <div className="mr-2">
                          <img src={YellowLogo} alt="" />
                        </div>
                        <h5
                          className=""
                          style={{
                            fontFamily: "Quiet Sans",
                            fontSize: " 20px",
                            fontWeight: 600,
                            lineHeight: " 62px",
                            letterSpacing: "-2px",
                            textAlign: "justify",
                          }}
                        >
                          Customer Focus
                        </h5>
                      </div>

                      <p className="ourstory-text">
                        Our focus is building outstanding <br /> customer
                        experience and lasting <br /> relationship.
                      </p>
                    </div>
                    <div className=" pt-4">
                      <div className="d-flex align-items-center ">
                        <div className="mr-2">
                          <img src={YellowLogo} alt="" />
                        </div>
                        <h5
                          className=""
                          style={{
                            fontFamily: "Quiet Sans",
                            fontSize: " 20px",
                            fontWeight: 600,
                            lineHeight: " 62px",
                            letterSpacing: "-2px",
                            textAlign: "justify",
                          }}
                        >
                          Customer Focus
                        </h5>
                      </div>

                      <p className="ourstory-text">
                        Our focus is building outstanding <br /> customer
                        experience and lasting <br /> relationship.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default coreValues