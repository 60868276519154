import BusinessCard from "../../components/Landing/BusinessCard";
import { data } from "./data";
import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
function Business() {
  const card = useRef();
  const { t } = useTranslation();

  const [intersecting, setIntersecting] = useState(false);
  const observer = new IntersectionObserver(
    (entries) => {
      const [entry] = entries;
      setIntersecting(entry.isIntersecting);
    },
    {
      threshold: 0.3,
    }
  );

  useEffect(() => {
    observer.observe(card.current);
    return () => {
      // observer.unobserve(card.current);
    };
  }, []);

  return (
    <div
      className="pt-4 business-section mt-5"
      style={
        {
          // height: "150vh",
        }
      }
    >
      <section
        className={
          intersecting
            ? "mt-5  mb-5 animate__animated animate__fadeOutUp "
            : `mt-5  mb-5 animate__animated animate__fadeInDown `
        }
      >
        <h2 className="broadcast-rm">
          Business payment solution for <br />{" "}
          <span className="grad-text">seamless</span> spending processes
        </h2>
      </section>
      <section className="dark-bg-sec overflow-hidden position-relative">
        <h2
          className={`broadcast-rm text-white pt-3  ${
            intersecting
              ? "animate__animated animate__fadeInDown "
              : "animate__animated animate__fadeOutUp "
          }`}
          style={{
            position: "sticky",
          }}
        >
          Business payment solution for <br />{" "}
          <span className="grad-text">seamless</span> spending processes
        </h2>

        <div
          className=" mx-auto"
          style={{
            width: "95%",
          }}
          ref={card}
        >
          <div className="row d-flex align-items-center plus-jarkata ">
            {data.map((i) => (
              <BusinessCard data={i} key={i.id} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Business;
